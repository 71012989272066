import React, { Component } from "react";
import PropTypes from 'prop-types';
import Image from "../images/image";
import VideoModal from '../video-modal';

import './index.scss';
class VideoOverlayImage extends Component {
	constructor(props) {
		super(props);
		this.onVideoClick = this.onVideoClick.bind(this);
		this.onVideoClose = this.onVideoClose.bind(this);
		this.state = {
			videoOpen: false
		};
	}

	onVideoClick() {
		this.setState({
			videoOpen: true
		});
	}

	onVideoClose() {
		this.setState({
			videoOpen: false
		});
	}

	youtubeIdParser(url) {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		const match = url.match(regExp);
		return (match && match[2].length == 11) ? match[2] : '';
	}

	render() {
		const { data, children, imgData, videoId, hasplay = false, imgClass = '', ...other } = this.props;

		return (
			<div className='video-modal-wrapper'>
				<div className={`video-image-thumbnail ${(hasplay ? 'has-play' : '')}`}
					onClick={this.onVideoClick}>
					{
						imgData &&
						<Image
							data={imgData}
							className={imgClass}
						/>
					}
				</div>

				<VideoModal
					videoId={children ? null : this.youtubeIdParser(videoId)}
					isOpen={this.state.videoOpen}
					onClose={this.onVideoClose}
					youtube={children ? false : true}
					{...other}
				>
					{children}
				</VideoModal>
			</div>
		);
	}
}
VideoOverlayImage.propTypes = {
	imgData: PropTypes.object,
	videoId: PropTypes.string,
	hasplay: PropTypes.bool,
	imgClass: PropTypes.string,
	children: PropTypes.node
};

export default VideoOverlayImage;
