import React, { Component } from "react";
import PropTypes from "prop-types";
import { Header, Footer } from '../../components';
import "../../components/common.scss";
import "./layout.scss";

export default class Layout extends Component {
	constructor(props) {
		super(props);
		this.skipTo = React.createRef();
		this.onSkipContentClick = this.onSkipContentClick.bind(this);
		console.log('props :>> ', props);
	}

	onSkipContentClick() {
		this.skipTo.current.focus();
	}

	render() {

		const { children, className, ...other } = this.props;

		return (
			<div id='pella-site-content' className={className || ""}>
				<main id='content' {...other} ref={this.skipTo}>
					<Header hideLinks={true} />
					{children}
					<Footer hideLinks={true}/>
				</main>
			</div>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.array,
	className: PropTypes.string
};
