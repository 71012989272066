import React from "react";
import PropTypes from "prop-types";

const Image = (props) => {
	const { data, altText='', ...other } = props;

	if (!data || !data.image)
		return null;

	const {
		image={},
		image_alt = "" ,
		image_title = "",
		image_alt_text = ""
	} = data;

	return (
		<img
			src={image.url}
			alt={image_alt || altText || image_alt_text}
			title={image_title.replace(/<br(\D\/>|\/>|>)/ig," ") || image_alt || image_alt_text}
			{...other}
		/>
	);
};

Image.propTypes = {
	data: PropTypes.object
};

export default Image;
