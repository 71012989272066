/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Container, Row, Column, Button, Select, TextInput } from '@pella/sash-foundation';

const SwatJobContactForm = (props) => {
	const { values, errors, handleViewSection, handleInputChange, onBlur, formType, expanded} = props;

	const [nextSection, setNextSection] = useState('');
	const { jobContactName, jobStreet, jobStreet2, jobCity, jobState, jobZip, jobBestContactNumber, jobBestContactEmail, jobTextNumber} = values;

	useEffect(() => {
		if (formType === 'PartsWithService') {
			setNextSection('ServiceInfo');
		} else {
			setNextSection('RoomInfo');
		}
	}, []);

	return (
		<Container>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobContactName' className='required'>Jobsite Contact Name:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobContactName'
						aria-required='true'
						value={jobContactName}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobContactName ? true : false}
						errorText={errors.jobContactName}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobStreet' className='required'>Street Address:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobStreet'
						aria-required='true'
						value={jobStreet}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobStreet ? true : false}
						errorText={errors.jobStreet}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobStreet2'>Street Address 2:</label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobStreet2'
						value={jobStreet2}
						onChange={(event) => handleInputChange(event)}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobCity' className='required'>City:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobCity'
						aria-required='true'
						value={jobCity}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobCity ? true : false}
						errorText={errors.jobCity}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobState' className='required'>State:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					{/* <TextInput
						name='jobState'
						value={jobState}
						onChange={(event) => handleInputChange(event)}
					/> */}
					<Select
						id='jobState'
						name='jobState'
						aria-required='true'
						value={jobState || ''}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobState ? true : false}
						errorText={errors.jobState}
					>
						<option value=''>Select...</option>
						<optgroup label='United States'>
							<option id='USA-AL' value='AL'>Alabama (AL)</option>
							<option id='USA-AK' value='AK'>Alaska (AK)</option>
							<option id='USA-AZ' value='AZ'>Arizona (AZ)</option>
							<option id='USA-AR' value='AR'>Arkansas (AR)</option>
							<option id='USA-CA' value='CA'>California (CA)</option>
							<option id='USA-CO' value='CO'>Colorado (CO)</option>
							<option id='USA-CT' value='CT'>Connecticut (CT)</option>
							<option id='USA-DE' value='DE'>Delaware (DE)</option>
							<option id='USA-DC' value='DC'>District of Columbia (DC)</option>
							<option id='USA-FL' value='FL'>Florida (FL)</option>
							<option id='USA-GA' value='GA'>Georgia (GA)</option>
							<option id='USA-GU' value='GU'>Guam (GU)</option>
							<option id='USA-HI' value='HI'>Hawaii (HI)</option>
							<option id='USA-ID' value='ID'>Idaho (ID)</option>
							<option id='USA-IL' value='IL'>Illinois (IL)</option>
							<option id='USA-IN' value='IN'>Indiana (IN)</option>
							<option id='USA-IA' value='IA'>Iowa (IA)</option>
							<option id='USA-KS' value='KS'>Kansas (KS)</option>
							<option id='USA-KY' value='KY'>Kentucky (KY)</option>
							<option id='USA-LA' value='LA'>Louisiana (LA)</option>
							<option id='USA-ME' value='ME'>Maine (ME)</option>
							<option id='USA-MD' value='MD'>Maryland (MD)</option>
							<option id='USA-MA' value='MA'>Massachusetts (MA)</option>
							<option id='USA-MI' value='MI'>Michigan (MI)</option>
							<option id='USA-MN' value='MN'>Minnesota (MN)</option>
							<option id='USA-MS' value='MS'>Mississippi (MS)</option>
							<option id='USA-MO' value='MO'>Missouri (MO)</option>
							<option id='USA-MT' value='MT'>Montana (MT)</option>
							<option id='USA-NE' value='NE'>Nebraska (NE)</option>
							<option id='USA-NV' value='NV'>Nevada (NV)</option>
							<option id='USA-NH' value='NH'>New Hampshire (NH)</option>
							<option id='USA-NJ' value='NJ'>New Jersey (NJ)</option>
							<option id='USA-NM' value='NM'>New Mexico (NM)</option>
							<option id='USA-NY' value='NY'>New York (NY)</option>
							<option id='USA-NC' value='NC'>North Carolina (NC)</option>
							<option id='USA-ND' value='ND'>North Dakota (ND)</option>
							<option id='USA-OH' value='OH'>Ohio (OH)</option>
							<option id='USA-OK' value='OK'>Oklahoma (OK)</option>
							<option id='USA-OR' value='OR'>Oregon (OR)</option>
							<option id='USA-PA' value='PA'>Pennsylvania (PA)</option>
							<option id='USA-PR' value='PR'>Puerto Rico (PR)</option>
							<option id='USA-RI' value='RI'>Rhode Island (RI)</option>
							<option id='USA-SC' value='SC'>South Carolina (SC)</option>
							<option id='USA-SD' value='SD'>South Dakota (SD)</option>
							<option id='USA-TN' value='TN'>Tennessee (TN)</option>
							<option id='USA-TX' value='TX'>Texas (TX)</option>
							<option id='USA-UT' value='UT'>Utah (UT)</option>
							<option id='USA-VT' value='VT'>Vermont (VT)</option>
							<option id='USA-VA' value='VA'>Virginia (VA)</option>
							<option id='USA-VI' value='VI'>Virgin Islands (VI)</option>
							<option id='USA-WA' value='WA'>Washington (WA)</option>
							<option id='USA-WV' value='WV'>West Virginia (WV)</option>
							<option id='USA-WI' value='WI'>Wisconsin (WI)</option>
							<option id='USA-WY' value='WY'>Wyoming (WY)</option>
						</optgroup>
						<optgroup label='Canada'>
							<option id='CAN-AB' value='AB'>Alberta (AB)</option>
							<option id='CAN-BC' value='BC'>British Columbia (BC)</option>
							<option id='CAN-MB' value='MB'>Manitoba (MB)</option>
							<option id='CAN-NB' value='NB'>New Brunswick (NB)</option>
							<option id='CAN-NL' value='NL'>Newfoundland and Labrador (NL)</option>
							<option id='CAN-NT' value='NT'>Northwest Territories (NT)</option>
							<option id='CAN-NS' value='NS'>Nova Scotia (NS)</option>
							<option id='CAN-NU' value='NU'>Nunavut (NU)</option>
							<option id='CAN-PE' value='PE'>Prince Edward Island (PE)</option>
							<option id='CAN-SK' value='SK'>Saskatchewan (SK)</option>
							<option id='CAN-ON' value='ON'>Ontario (ON)</option>
							<option id='CAN-QC' value='QC'>Quebec (QC)</option>
							<option id='CAN-YT' value='YT'>Yukon (YT)</option>
						</optgroup>
					</Select>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobZip' className='required'>Zip Code:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobZip'
						aria-required='true'
						value={jobZip}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobZip ? true : false}
						errorText={errors.jobZip}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobBestContactNumber' className='required'>Best Contact Number:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobBestContactNumber'
						aria-required='true'
						value={jobBestContactNumber}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobBestContactNumber ? true : false}
						errorText={errors.jobBestContactNumber}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobBestContactEmail' className='required'>Best Contact Email:<span style={{ color: '#a70215' }}>*</span></label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobBestContactEmail'
						aria-required='true'
						value={jobBestContactEmail}
						onChange={(event) => handleInputChange(event)}
						onBlur={(event) => onBlur(event)}
						error={errors.jobBestContactEmail ? true : false}
						errorText={errors.jobBestContactEmail}
					/>
				</Column>
			</Row>
			<Row>
				<Column xs={12} sm={6}><label htmlFor='jobTextNumber'>Text Number:</label></Column>
				<Column xs={12} sm={6}>
					<TextInput
						name='jobTextNumber'
						value={jobTextNumber}
						onChange={(event) => handleInputChange(event)}
					/>
				</Column>
			</Row>

			{ !expanded &&
				<Row className='sf-justify-content--end'>
					<Button  className='sf-mr--md' type='button' color='white' onClick={() => handleViewSection('StoreInfo')} >Previous</Button>
					<Button color='primary' type='button' onClick={() => handleViewSection(nextSection)} >Next</Button>
				</Row>
			}
		</Container>
	);
};

export default SwatJobContactForm;
