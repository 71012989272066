/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Row, TextArea, Button } from '@pella/sash-foundation';

const SwatServiceForm = (props) => {
	const { values, handleViewSection, handleInputChange, expanded}  = props;

	return (
		<Container>
			<Row>
				<TextArea
					name='serviceNeed'
					labelText='What is your service need?'
					assistiveText = {'Example: (Reglazing fixed panel for 250 SPD; Impervia Casement window will not crank open and sash is sagging)'}
					rows='5'
					noWrapper
					value={values.serviceNeed || ''}
					onChange={(event) => handleInputChange(event)}
				/>
				<span>(please include as many details as possible)</span>
			</Row>

			{ !expanded &&
				<Row className='sf-justify-content--end'>
					<Button className='sf-mr--md' type='button' color='white' onClick={() => handleViewSection('JobInfo')} >Previous</Button>
					<Button color='primary' type='button' onClick={() => handleViewSection('RoomInfo')} >Next</Button>
				</Row>
			}
		</Container>
	);
};

export default SwatServiceForm;



