import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import sessionStorage from 'sessionstorage';
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md';
import { Container, Row, Column, Checkbox, List, TextInput, Select, TextArea, Button, Alert } from '@pella/sash-foundation';

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";

import './index.scss';

class SwatRequest extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			success: false,
			submitError: false,
			states: [
				{ name: 'Alabama' },
				{ name: 'Alaska' },
				{ name: 'Arizona' },
				{ name: 'Arkansas' },
				{ name: 'California' },
				{ name: 'Colorado' },
				{ name: 'Connecticut' },
				{ name: 'Delaware' },
				{ name: 'Florida' },
				{ name: 'Georgia' },
				{ name: 'Hawaii' },
				{ name: 'Idaho' },
				{ name: 'Illinois' },
				{ name: 'Indiana' },
				{ name: 'Iowa' },
				{ name: 'Kansas' },
				{ name: 'Kentucky' },
				{ name: 'Louisiana' },
				{ name: 'Maine' },
				{ name: 'Maryland' },
				{ name: 'Massachusetts' },
				{ name: 'Michigan' },
				{ name: 'Minnesota' },
				{ name: 'Mississippi' },
				{ name: 'Missouri' },
				{ name: 'Montana' },
				{ name: 'Nebraska' },
				{ name: 'Nevada' },
				{ name: 'New Hampshire' },
				{ name: 'New Jersey' },
				{ name: 'New Mexico' },
				{ name: 'New York' },
				{ name: 'North Carolina' },
				{ name: 'North Dakota' },
				{ name: 'Ohio' },
				{ name: 'Oklahoma' },
				{ name: 'Oregon' },
				{ name: 'Pennsylvania' },
				{ name: 'Rhode Island' },
				{ name: 'South Carolina' },
				{ name: 'South Dakota' },
				{ name: 'Tennessee' },
				{ name: 'Texas' },
				{ name: 'Utah' },
				{ name: 'Vermont' },
				{ name: 'Virginia' },
				{ name: 'Washington' },
				{ name: 'West Virginia' },
				{ name: 'Wisconsin' },
				{ name: 'Wyoming' },
			],
			counter: 1,
			rows: [0],
			selectedFile: [null],
			partsArray: [{ description: "", quantity: "" }],
			loading: false,
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	componentDidMount() {
		const date = new Date();

		// Set date automatically to today's date.
		this.setState({
			txtDate: date.toISOString().substr(0, 10)
		});


		// Check sessionstroage and set user info
		if (sessionStorage.getItem('userInfo')) {
			const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

			this.setState({
				txtFirstName: userInfo.firstName,
				txtLastName: userInfo.lastName,
				txtEmail: userInfo.email,
				txtStoreName: userInfo.company !== 'Pella' ? userInfo.company : userInfo.company === 'Pella' ? sessionStorage.getItem('selectedCompany') : ''
			});
		}
	}

	onInputChange(e) {
		if (e.target.type === 'checkbox') {
			const checks = document.getElementsByTagName('input');
			const partsCheck = [];
			const cblProductNeedService = [];
			const cblProductLocation = [];

			for (let i = 0; i < checks.length; i++) {
				if (checks[i].type === 'checkbox' && checks[i].checked) {
					checks[i].name === 'partsCheck' ? partsCheck.push(checks[i].value)
						: checks[i].name === 'cblProductNeedService' ? cblProductNeedService.push(checks[i].value)
							: checks[i].name === 'cblProductLocation' ? cblProductLocation.push(checks[i].value)
								: null;
				}
			}

			this.setState({
				partsCheck: partsCheck,
				cblProductNeedService: cblProductNeedService,
				cblProductLocation: cblProductLocation,
				success: false,
				submitError: false
			});
		} else if (e.target.type === 'file') {

			this.setState({
				[e.target.name]: [e.target.files],
				selectedFile: e.target.files,
				loaded: 0,
				success: false,
				submitError: false
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value,
				success: false,
				submitError: false
			});
			if (e.target.name === 'rblDeliverTo' && e.target.value === "Store") {
				this.setState({
					dvGroundCheck: '',
					txtShippingName: '',
					txtShippingAddress: '',
					txtShippingCity: '',
					txtShippingState: '',
					txtShippingZip: '',
					txtShippingPhone: '',
					submitError: false,
				});
			}
		}
	}

	validateFields() {
		let errors = [];

		// Required fields on both forms
		if (!this.state.txtStoreName) {
			errors.txtStoreName = 'Please enter a store name.';
		}

		if (!this.state.txtStoreCity) {
			errors.txtStoreCity = 'Please enter a store city.';
		}

		if (!this.state.ddlState) {
			errors.ddlState = 'Please select a store state.';
		}

		if (!this.state.txtFirstName || !this.state.txtLastName) {
			errors.txtName = 'First and last name are both required';
		}

		if (!this.state.txtEmail) {
			errors.txtEmail = 'Please enter an email address.';
		}

		if (!this.state.txtPhone) {
			errors.txtPhone = 'Please enter a phone number.';
		}

		if (!this.state.txtDate) {
			errors.txtDate = 'Please enter a date.';
		}

		if (!this.state.partsArray[0].description) {
			errors.txtFreePartsDescription = 'Please enter a part name, part number or description of part needed.';
		}

		if (!this.state.partsCheck || this.state.partsCheck.length < 1) {
			errors.partsCheck = 'Please select at least one issue description.';
		}


		// Required fields on Parts form
		if (this.state.rblServiceType && this.state.rblServiceType === 'Parts') {
			if (!this.state.rblDeliverTo) {
				errors.rblDeliverTo = 'Please select where to deliver parts to.';
			}

			if (this.state.rblDeliverTo === 'Store' && !this.state.txtJobName) {
				errors.txtJobName = 'Please enter a job name.';
			}

			if (this.state.rblDeliverTo === 'Other Location' && !this.state.dvGroundCheck) {
				errors.dvGroundCheck = 'Please select an option for ground shipping.';
			}

			if (this.state.rblDeliverTo === 'Other Location' && !this.state.txtShippingName) {
				errors.txtShippingName = 'Please enter a shipping name.';
			}

			if (this.state.rblDeliverTo === 'Other Location' && !this.state.txtShippingAddress) {
				errors.txtShippingAddress = 'Please enter a shipping address.';
			}

			if (this.state.rblDeliverTo === 'Other Location' && !this.state.txtShippingCity) {
				errors.txtShippingCity = 'Please enter a shipping city.';
			}

			if (this.state.rblDeliverTo === 'Other Location' && !this.state.txtShippingState) {
				errors.txtShippingState = 'Please select a state.';
			}

			if (this.state.rblDeliverTo === 'Other Location' && !this.state.txtShippingZip) {
				errors.txtShippingZip = 'Please enter a shipping zip.';
			}
		}

		// Required fields on Service form
		if (this.state.rblServiceType && this.state.rblServiceType === 'Service') {
			if (!this.state.txtJobName) {
				errors.txtJobName = 'Please enter a job name.';
			}

			if (!this.state.txtContactFirstName) {
				errors.txtContactFirstName = 'Please enter a first name.';
			}

			if (!this.state.txtContactLastName) {
				errors.txtContactLastName = 'Please enter a last name.';
			}

			if (!this.state.txtContactPhone) {
				errors.txtContactPhone = 'Please enter a phone number.';
			}

			if (!this.state.txtContactEmail) {
				errors.txtContactEmail = 'Please enter a valid email address.';
			}

			if (!this.state.txtServiceAddress1) {
				errors.txtServiceAddress1 = 'Please enter an address.';
			}

			if (!this.state.txtServiceCity) {
				errors.txtServiceCity = 'Please enter a city';
			}

			if (!this.state.txtServiceState) {
				errors.txtServiceState = 'Please select a state.';
			}

			if (!this.state.txtServiceZip) {
				errors.txtServiceZip = 'Please enter a zip.';
			}

			if (!this.state.cblProductNeedService || this.state.cblProductNeedService.length < 1) {
				errors.cblProductNeedService = 'Please select at least 1 product.';
			}

			if (!this.state.cblProductLocation || this.state.cblProductLocation.length < 1) {
				errors.cblProductLocation = 'Please select at least 1 product location.';
			}
		}

		return errors;
	}

	onFormSubmit(e) {
		e.preventDefault();

		const { rblServiceType, rblDeliverTo, txtJobName, dvGroundCheck, txtStoreName, txtStoreCity, ddlState, txtFirstName, txtLastName, txtEmail, txtPhone, txtDate, partsCheck, cblProductLocation, cblProductNeedService, txtContactFirstName, txtContactLastName, txtContactPhone, txtContactEmail, txtServiceAddress1, txtServiceCity, txtServiceState, txtServiceZip, txtShippingName, txtShippingAddress, txtShippingCity, txtShippingState, txtShippingZip, partsArray } = this.state;

		const errors = this.validateFields(rblServiceType, rblDeliverTo, txtJobName, dvGroundCheck, txtStoreName, txtStoreCity, ddlState, txtFirstName, txtLastName, txtEmail, txtPhone, txtDate, partsCheck, cblProductLocation, cblProductNeedService, txtContactFirstName, txtContactLastName, txtContactPhone, txtContactEmail, txtServiceAddress1, txtServiceCity, txtServiceState, txtServiceZip, txtShippingName, txtShippingAddress, txtShippingCity, txtShippingState, txtShippingZip, partsArray[0].description);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors
			});
		} else {
			const emailData = {
				request_type: this.state.rblServiceType,
				job_name: this.state.txtJobName || '',
				store_name: this.state.txtStoreName,
				store_city: this.state.txtStoreCity,
				store_state: this.state.ddlState,
				store_number: this.state.txtStoreNumber || '',
				store_phone: this.state.txtStorePhone || '',
				submitted_by: this.state.txtFirstName + ' ' + this.state.txtLastName,
				email: this.state.txtEmail,
				phone_number: this.state.txtPhone,
				date: this.state.txtDate,
				// original_purchase_date: this.state.txtPurchaseDate || '',
				original_store_po: this.state.txtStorePO || '',
				new_po: this.state.txtNewPO || '',
				// pqm_quote_number: this.state.txtPQMQuote || '',
				// pella_order_number: this.state.txtPellaOrderNumber || '',
				multiple_line_items: this.state.txtMultipleLineItems || '',
				description_of_issue: this.state.partsCheck,
				parts_information: this.state.partsArray,
				parts: {
					deliver_parts_to: this.state.rblDeliverTo,
					ground_shipping: this.state.dvGroundCheck || 'N/A',
					shipping_name: this.state.txtShippingName || 'N/A',
					shipping_address: this.state.txtShippingAddress || 'N/A',
					shipping_city: this.state.txtShippingCity || 'N/A',
					shipping_state: this.state.txtShippingState || 'N/A',
					shipping_zip: this.state.txtShippingZip || 'N/A',
					shipping_phone: this.state.txtShippingPhone || 'N/A',
				},
				service: {
					contact_name: this.state.txtContactFirstName + " " + this.state.txtContactLastName || '',
					contact_phone: this.state.txtContactPhone,
					contact_email: this.state.txtContactEmail,
					address_to_service: this.state.txtServiceAddress1,
					address2_to_service: this.state.txtServiceAddress2 || '',
					service_city: this.state.txtServiceCity,
					service_state: this.state.txtServiceState,
					service_zip: this.state.txtServiceZip,
					necessary: this.state.ddlPartOrdered || '',
					comments: this.state.txtComments || '',
					product_needing_serviced: this.state.cblProductNeedService,
					product_location: this.state.cblProductLocation,
					special_requests: this.state.txtSpecialRequests || ''
				}
			};

			this.setState({
				loading: true
			});

			const data = new FormData();

			if (this.state.selectedFile[0] != null) {
				for (var x = 0; x < this.state.selectedFile.length; x++) {
					data.append('file', this.state.selectedFile[x]), this.state.selectedFile[x].name;
				}
				data.append("data", JSON.stringify(emailData));
			} else {
				data.append("data", JSON.stringify(emailData));
			}


			if (this.state.rblServiceType == "Parts") {

				// axios.post('http://localhost:4000/pella-coop/v1/email/parts', data, {
				axios.post(`${process.env.GATSBY_PELLA_API}/pella-coop/v1/email/parts`, data, {
					headers: {
						'Content-Type': `multipart/form-data`,
						Authorization:
							"Bearer " + process.env.GATSBY_PELLA_API_TOKEN
					}
				}).then(response => {
					if (response.data.success === true) {
						window.scrollTo(0, 0);
						this.setState({
							loading: false,
							success: true,
							errors: [],
						});
					} else {
						this.setState({
							loading: false,
							success: false
						});
					}
				}).catch(error => {
					window.scrollTo(0, 0);
					this.setState({
						loading: false,
						submitError: true
					});
				});
			}

			if (this.state.rblServiceType == "Service") {

				// axios.post('http://localhost:4000/pella-coop/v1/email/service', data, {
				axios.post(`${process.env.GATSBY_PELLA_API}/pella-coop/v1/email/service`, data, {
					headers: {
						'Content-Type': `multipart/form-data`,
						Authorization:
							"Bearer " + process.env.GATSBY_PELLA_API_TOKEN
					}
				}).then(response => {
					if (response.data.success === true) {
						window.scrollTo(0, 0);
						this.setState({
							loading: false,
							success: true,
							errors: [],
						});
					} else {
						this.setState({
							loading: false,
							success: false
						});
					}
				}).catch(error => {
					window.scrollTo(0, 0);
					this.setState({
						loading: false,
						submitError: true
					});
				});
			}
		}
	}

	handlePartDescriptionChange = id => evt => {
		const newParts = this.state.partsArray.map((partitem, partsid) => {
			if (id !== partsid) return partitem;
			return { ...partitem, description: evt.target.value };
		});
		this.setState({
			partsArray: newParts,
			success: false,
			submitError: false
		});
	};
	handlePartQuantityChange = id => evt => {
		const newParts = this.state.partsArray.map((partitem, partsid) => {
			if (id !== partsid) return partitem;
			return { ...partitem, quantity: evt.target.value };
		});
		this.setState({
			partsArray: newParts,
			success: false,
			submitError: false
		});
	};

	handleAddPartItem = () => {
		this.setState({
			partsArray: this.state.partsArray.concat([{ description: "" }])
		});
	};

	handleRemovePartItem = id => () => {
		this.setState({
			partsArray: this.state.partsArray.filter((s, partsid) => id !== partsid)
		});
	};

	render() {
		const { loading } = this.state;

		return (
			<Layout>
				{loading ? (
					<div id='overlay' className='spinner'>
						<img src={require("../../../images/spinner.svg")} />
					</div>) : null}
				<SEO title='SWAT Request Form' />
				{/* <Nav /> */}

				<Container width={1200} className='sf-pb--2xl'>
					<h1 style={{ fontSize: '2rem', color: '#676767' }} className='sf-text--uppercase sf-text--semibold'>(SWAT) REQUEST FOR SERVICE FORM</h1>

					<div className='radius-box'>
						<p className='radius-box-title'>Request For Parts or Service</p>

						{
							this.state.success &&
							<Alert type='success' hideClose={true}>This form has been successfully submitted.</Alert>
						}

						{
							this.state.submitError &&
							<Alert type='error' hideClose={true}>There was a problem submitting the form. If the problem persists please contact <a href='mailto:portalsupport@pella.com?SUBJECT=SWAT Form Submission Problem'>portalsupport@pella.com</a> </Alert>
						}

						<form
							id='swat-form'
							autoComplete='off'
							noValidate={true}
							encType='multipart/form-data'
						>
							<fieldset className='sf-formGroup'>
								<legend style={{ fontSize: '0.875rem', marginBottom: '0.375rem' }} className='sf-display--block required'>Please select one</legend>

								<Checkbox
									type='radio'
									id='rblServiceType_0'
									labelText='This is a request for PARTS ONLY (no full units) - no technician needed.'
									value='Parts'
									group='rblServiceType'
									onChange={this.onInputChange}
								/>

								<Checkbox
									type='radio'
									id='rblServiceType_1'
									labelText='This is a request for a SERVICE CALL. (This call may or may not include parts.)'
									value='Service'
									group='rblServiceType'
									onChange={this.onInputChange}
								/>
							</fieldset>

							{
								this.state.rblServiceType &&
								<>
									<hr />

									<Row>
										{
											this.state.rblServiceType === 'Parts' &&
											<Column xs={12} md={4}>
												<fieldset className='sf-formGroup'>
													<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Deliver parts to:</legend>
													<List
														layout='inline'
														bullets='none'
														className='inline-radios'
													>
														<li>
															<Checkbox
																type='radio'
																id='rblDeliverTo_0'
																labelText='Store'
																value='Store'
																group='rblDeliverTo'
																onChange={this.onInputChange}
															/>
														</li>

														<li>
															<Checkbox
																type='radio'
																id='rblDeliverTo_1'
																labelText='Other Location'
																value='Other Location'
																group='rblDeliverTo'
																onChange={this.onInputChange}
															/>
														</li>
													</List>

													{this.state.errors.rblDeliverTo && <p className='error-text'>{this.state.errors.rblDeliverTo}</p>}
												</fieldset>
											</Column>
										}

										{
											((this.state.rblServiceType === 'Parts' &&
												this.state.rblDeliverTo === 'Store') ||
												this.state.rblServiceType === 'Service') &&
											<Column xs={12} md={4}>
												<TextInput
													id='txtJobName'
													labelText='Job Name'
													required={true}
													onChange={this.onInputChange}
												/>

												{this.state.errors.txtJobName && <p className='error-text'>{this.state.errors.txtJobName}</p>}
											</Column>
										}

										{
											this.state.rblServiceType === 'Parts' &&
											this.state.rblDeliverTo === 'Other Location' &&
											<Column xs={12} md={4}>
												<fieldset className='sf-formGroup'>
													<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Ground Shipping</legend>
													<List bullets='none' layout='inline'>
														<li>
															<Checkbox
																type='radio'
																id='rblGroundShip_0'
																labelText='Yes'
																value='Yes'
																group='dvGroundCheck'
																onChange={this.onInputChange}
															/>
														</li>

														<li className='sf-ml--lg'>
															<Checkbox
																type='radio'
																id='rblGroundShip_1'
																labelText='No'
																value='No'
																group='dvGroundCheck'
																onChange={this.onInputChange}
															/>
														</li>
													</List>
												</fieldset>

												{this.state.errors.dvGroundCheck && <p className='error-text'>{this.state.errors.dvGroundCheck}</p>}
											</Column>
										}
									</Row>

									<hr />

									<Row verticalGutters={true}>
										<Column xs={12} md={3}>
											<TextInput
												id='txtStoreName'
												labelText='Store Name'
												required={true}
												onChange={this.onInputChange}
												value={this.state.txtStoreName}
											/>

											{this.state.errors.txtStoreName && <p className='error-text'>{this.state.errors.txtStoreName}</p>}
										</Column>

										<Column xs={12} md={3}>
											<TextInput
												id='txtStoreCity'
												labelText='Store City'
												required={true}
												onChange={this.onInputChange}
											/>

											{this.state.errors.txtStoreCity && <p className='error-text'>{this.state.errors.txtStoreCity}</p>}
										</Column>

										<Column xs={12} md={2}>
											<Select
												id='ddlState'
												labelText='Store State'
												required={true}
												onChange={this.onInputChange}
											>
												<option value=''>--- Select ---</option>
												{this.state.states.map((state, index) => (
													<option key={index} value={state.name}>{state.name}</option>
												))}
											</Select>

											{this.state.errors.ddlState && <p className='error-text'>{this.state.errors.ddlState}</p>}
										</Column>

										<Column xs={12} md={2}>
											<TextInput
												id='txtStoreNumber'
												labelText='Store Number'
												onChange={this.onInputChange}
											/>
										</Column>

										<Column xs={12} md={2}>
											<TextInput
												type='tel'
												id='txtStorePhone'
												labelText='Store Phone'
												onChange={this.onInputChange}
												placeholder='XXX-XXX-XXXX'
											/>
										</Column>



										{ /* Line 2 */}

										<Column xs={12} md={5}>
											<fieldset className='sf-formGroup'>
												<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Submitted By</legend>
												<Row>
													<Column xs={12} md={6}>
														<TextInput
															id='txtFirstName'
															labelText='First Name'
															hideLabel={true}
															required={true}
															onChange={this.onInputChange}
															placeholder='First Name'
															value={this.state.txtFirstName || ''}
														/>
													</Column>

													<Column xs={12} md={6}>
														<TextInput
															id='txtLastName'
															labelText='Last Name'
															hideLabel={true}
															required={true}
															onChange={this.onInputChange}
															placeholder='Last Name'
															value={this.state.txtLastName || ''}
														/>
													</Column>
												</Row>
											</fieldset>

											{this.state.errors.txtName && <p className='error-text'>{this.state.errors.txtName}</p>}
										</Column>

										<Column xs={12} md={3}>
											<TextInput
												type='email'
												id='txtEmail'
												labelText='Email'
												required={true}
												onChange={this.onInputChange}
												placeholder='example@email.com'
												value={this.state.txtEmail}
											/>

											{this.state.errors.txtEmail && <p className='error-text'>{this.state.errors.txtEmail}</p>}
										</Column>

										<Column xs={12} md={2}>
											<TextInput
												id='txtPhone'
												labelText='Phone Number'
												required={true}
												onChange={this.onInputChange}
												placeholder='XXX-XXX-XXXX'
											/>

											{this.state.errors.txtPhone && <p className='error-text'>{this.state.errors.txtPhone}</p>}
										</Column>

										<Column xs={12} md={2}>
											<TextInput
												type='date'
												id='txtDate'
												labelText='Date'
												required={true}
												onChange={this.onInputChange}
												value={this.state.txtDate}
											/>

											{this.state.errors.txtDate && <p className='error-text'>{this.state.errors.txtDate}</p>}
										</Column>
									</Row>


									{
										this.state.rblServiceType === 'Service' &&
										<>
											<hr />
											<h3 className='sf-text--center sf-mb--lg'>Servicing Information</h3>
											<hr />

											<Row verticalGutters>
												<Column xs={12} md={3}>
													<TextInput
														id='txtContactFirstName'
														labelText='Contact First Name'
														required={true}
														onChange={this.onInputChange}
														value={this.state.txtContactFirstName}
													/>
													{this.state.errors.txtContactFirstName && <p className='error-text'>{this.state.errors.txtContactFirstName}</p>}
												</Column>

												<Column xs={12} md={3}>
													<TextInput
														id='txtContactLastName'
														labelText='Contact Last Name'
														required={true}
														onChange={this.onInputChange}
														value={this.state.txtContactLastName}
													/>
													{this.state.errors.txtContactLastName && <p className='error-text'>{this.state.errors.txtContactLastName}</p>}
												</Column>

												<Column xs={12} md={3}>
													<TextInput
														type='tel'
														id='txtContactPhone'
														labelText='Contact Phone'
														required={true}
														onChange={this.onInputChange}
													/>
													{this.state.errors.txtContactPhone && <p className='error-text'>{this.state.errors.txtContactPhone}</p>}
												</Column>

												<Column xs={12} md={3}>
													<TextInput
														type='email'
														id='txtContactEmail'
														labelText='Contact Email'
														required={true}
														onChange={this.onInputChange}
													/>
													{this.state.errors.txtContactEmail && <p className='error-text sf-mb--sm'>{this.state.errors.txtContactEmail}</p>}
													<p className='swat-form-hint sf-mb--0'>Note: If not available, use the sales rep email address.</p>
												</Column>

												<Column xs={12} md={6}>
													<fieldset className='sf-formGroup'>
														<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Address to Service</legend>
														<Row>
															<Column xs={12} md={6}>
																<TextInput
																	id='txtServiceAddress1'
																	labelText='Address 1'
																	hideLabel={true}
																	required={true}
																	onChange={this.onInputChange}
																	placeholder='Street Address'
																/>
															</Column>

															<Column xs={12} md={6}>
																<TextInput
																	id='txtServiceAddress2'
																	labelText='Address 2'
																	onChange={this.onInputChange}
																	hideLabel={true}
																	placeholder='Street Address Line 2'
																/>
															</Column>
														</Row>
													</fieldset>

													{this.state.errors.txtServiceAddress1 && <p className='error-text'>{this.state.errors.txtServiceAddress1}</p>}
												</Column>

												<Column xs={12} md={2}>
													<TextInput
														id='txtServiceCity'
														labelText='City'
														required={true}
														onChange={this.onInputChange}
														placeholder='City'
													/>

													{this.state.errors.txtServiceCity && <p className='error-text'>{this.state.errors.txtServiceCity}</p>}
												</Column>

												<Column xs={12} md={2}>
													<Select
														id='txtServiceState'
														labelText='State'
														required={true}
														onChange={this.onInputChange}
													>
														<option value=''>--- Select ---</option>
														{this.state.states.map((state, index) => (
															<option key={index} value={state.name}>{state.name}</option>
														))}
													</Select>

													{this.state.errors.txtServiceState && <p className='error-text'>{this.state.errors.txtServiceState}</p>}
												</Column>

												<Column xs={12} md={2}>
													<TextInput
														id='txtServiceZip'
														labelText='Zip'
														required={true}
														onChange={this.onInputChange}
														placeholder='Zip'
													/>

													{this.state.errors.txtServiceZip && <p className='error-text'>{this.state.errors.txtServiceZip}</p>}
												</Column>
											</Row>
										</>
									}


									{
										this.state.rblDeliverTo === 'Other Location' &&
										<>
											<hr />

											<p className='sf-text--bold sf-text--center'>Please Fill Out Shipping Address if Different from Store Address.</p>

											<Row>
												<Column xs={12} md={2}>
													<TextInput
														id='txtShippingName'
														labelText='Shipping Name'
														required={true}
														onChange={this.onInputChange}
													/>

													{this.state.errors.txtShippingName && <p className='error-text'>{this.state.errors.txtShippingName}</p>}
												</Column>

												<Column xs={12} md={2}>
													<TextInput
														id='txtShippingAddress'
														labelText='Shipping Address'
														required={true}
														onChange={this.onInputChange}
													/>

													{this.state.errors.txtShippingAddress && <p className='error-text'>{this.state.errors.txtShippingAddress}</p>}
												</Column>

												<Column xs={12} md={2}>
													<TextInput
														id='txtShippingCity'
														labelText='Shipping City'
														required={true}
														onChange={this.onInputChange}
													/>

													{this.state.errors.txtShippingCity && <p className='error-text'>{this.state.errors.txtShippingCity}</p>}
												</Column>

												<Column xs={12} md={2}>
													<Select
														id='txtShippingState'
														labelText='Shipping State'
														required={true}
														onChange={this.onInputChange}
													>
														<option value=''>--- Select ---</option>
														{this.state.states.map((state, index) => (
															<option key={index} value={state.name}>{state.name}</option>
														))}
													</Select>

													{this.state.errors.txtShippingState && <p className='error-text'>{this.state.errors.txtShippingState}</p>}
												</Column>

												<Column xs={12} md={2}>
													<TextInput
														id='txtShippingZip'
														labelText='Shipping Zip'
														required={true}
														onChange={this.onInputChange}
													/>

													{this.state.errors.txtShippingZip && <p className='error-text'>{this.state.errors.txtShippingZip}</p>}
												</Column>

												<Column xs={12} md={2}>
													<TextInput
														id='txtShippingPhone'
														labelText='Shipping Phone'
														onChange={this.onInputChange}
													/>
												</Column>
											</Row>
										</>
									}


									{
										this.state.rblServiceType === 'Service' &&
										<>
											<hr />
											<Row>
												<Column xs={12} md={4}>
													<Select
														id='ddlPartOrdered'
														labelText='If necessary, has a part already been ordered?'
														onChange={this.onInputChange}
													>
														<option value=''></option>
														<option value='Yes'>Yes</option>
														<option value='No'>No</option>
													</Select>
												</Column>

												<Column xs={12} md={8}>
													<TextArea
														id='txtComments'
														labelText='Comments'
														onChange={this.onInputChange}
														rows={3}
													/>
												</Column>
											</Row>
										</>
									}


									<hr />

									{/* Row 3 */}
									<Row verticalGutters={true}>
										<Column xs={12} md={3}>
											<TextInput
												id='txtStorePO'
												labelText='Original Store PO #'
												onChange={this.onInputChange}
											/>
										</Column>

										<Column xs={12} md={3}>
											<TextInput
												id='txtNewPO'
												labelText='New PO # (if necessary)'
												onChange={this.onInputChange}
											/>
										</Column>


										{/* <Column xs={12} md={3}>
											<TextInput
												type='date'
												id='txtPurchaseDate'
												labelText='Original Purchase Date'
												onChange={this.onInputChange}
											/>
										</Column>

										<Column xs={12} md={2}>
											<TextInput
												id='txtNewPO'
												labelText='New PO #'
												onChange={this.onInputChange}
											/>
										</Column>

										<Column xs={12} md={2}>
											<TextInput
												id='txtPQMQuote'
												labelText='PQM Quote Number'
												onChange={this.onInputChange}
											/>
										</Column>

										<Column xs={12} md={3}>
											<TextInput
												id='txtPellaOrderNumber'
												labelText='Pella Order # (if available)'
												onChange={this.onInputChange}
											/>
										</Column> */}

										<Column xs={12}>
											<TextArea
												id='txtMultipleLineItems'
												labelText='If Multiple Line Items, please specify Line # and/or sizing information:'
												onChange={this.onInputChange}
												rows={3}
											/>
										</Column>

										<Column xs={12} md={this.state.rblServiceType === 'Service' ? 6 : 12}>
											<fieldset className='sf-formGroup description-fieldset'>
												<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Description of Issue</legend>
												<Row>
													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_0'
															group='partsCheck'
															value='Installation'
															labelText='Installation'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_1'
															group='partsCheck'
															value='Air/Water Filtration'
															labelText='Air/Water Filtration'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_2'
															group='partsCheck'
															value='Broken/Damaged'
															labelText='Broken/Damaged'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_3'
															group='partsCheck'
															value='Wrong Item'
															labelText='Wrong Item'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_4'
															group='partsCheck'
															value='Missing'
															labelText='Missing'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_5'
															group='partsCheck'
															value='Aesthetic/Appearance'
															labelText='Aesthetic/Appearance'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_6'
															group='partsCheck'
															value='Diagnosis'
															labelText='Diagnosis'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_7'
															group='partsCheck'
															value='Other'
															labelText='Other'
															onChange={this.onInputChange}
														/>
													</Column>

													<Column xs={6}>
														<Checkbox
															id='cblDescriptionIssue_8'
															group='partsCheck'
															value='Operational'
															labelText='Operational'
															onChange={this.onInputChange}
														/>
													</Column>
												</Row>
											</fieldset>

											{this.state.errors.partsCheck && <p className='error-text'>{this.state.errors.partsCheck}</p>}
										</Column>

										{
											this.state.rblServiceType === 'Service' &&
											<>
												<Column xs={12} md={6}>
													<fieldset className='sf-formGroup description-fieldset'>
														<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Product Needing Serviced</legend>
														<Row>
															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_0'
																	group='cblProductNeedService'
																	value='Encompass'
																	labelText='Encompass'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_1'
																	group='cblProductNeedService'
																	value='Architect'
																	labelText='Architect'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_2'
																	group='cblProductNeedService'
																	value='Proline (1992-2019)'
																	labelText='Proline (1992-2019)'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_3'
																	group='cblProductNeedService'
																	value='Entry Systems'
																	labelText='Entry Systems'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_4'
																	group='cblProductNeedService'
																	value='250'
																	labelText='250'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_5'
																	group='cblProductNeedService'
																	value='Impervia'
																	labelText='Impervia'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_6'
																	group='cblProductNeedService'
																	value='Designer (1992-2019)'
																	labelText='Designer (1992-2019)'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_7'
																	group='cblProductNeedService'
																	value='Lifestyle'
																	labelText='Lifestyle'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductNeedService_8'
																	group='cblProductNeedService'
																	value='350'
																	labelText='350'
																	onChange={this.onInputChange}
																/>
															</Column>
														</Row>
													</fieldset>

													{this.state.errors.cblProductNeedService && <p className='error-text'>{this.state.errors.cblProductNeedService}</p>}
												</Column>

												<Column xs={12} md={6}>
													<fieldset className='sf-formGroup description-fieldset'>
														<legend style={{ fontSize: '0.875rem', marginBottom: '0.125rem' }} className='sf-display--block required'>Product Location</legend>
														<Row>
															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_0'
																	group='cblProductLocation'
																	value='1st Floor'
																	labelText='1st Floor'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_1'
																	group='cblProductLocation'
																	value='3rd Floor'
																	labelText='3rd Floor'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_2'
																	group='cblProductLocation'
																	value='Living Room'
																	labelText='Living Room'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_3'
																	group='cblProductLocation'
																	value='Bedroom'
																	labelText='Bedroom'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_4'
																	group='cblProductLocation'
																	value='2nd Floor'
																	labelText='2nd Floor'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_5'
																	group='cblProductLocation'
																	value='Other'
																	labelText='Other'
																	onChange={this.onInputChange}
																/>
															</Column>

															<Column xs={6}>
																<Checkbox
																	id='cblProductLocation_6'
																	group='cblProductLocation'
																	value='Kitchen'
																	labelText='Kitchen'
																	onChange={this.onInputChange}
																/>
															</Column>
														</Row>
													</fieldset>

													{this.state.errors.cblProductLocation && <p className='error-text'>{this.state.errors.cblProductLocation}</p>}
												</Column>
											</>
										}
									</Row>

									{ /* Adding Parts */}

									{this.state.partsArray.map((part, id) => (

										<Row id={`js-row-${id}`} key={id} verticalGutters={true}>

											<Column xs={12} md={8}>
												<TextArea
													id={`txtFreePartsDescription${id > 0 ? id : ''}`}
													labelText='Please list part name, part number or description of part needed'
													required={true}
													value={part.description}
													onChange={this.handlePartDescriptionChange(id)}
												/>

												{this.state.errors.txtFreePartsDescription && <p className='error-text'>{this.state.errors.txtFreePartsDescription}</p>}
											</Column>
											<Column xs={12} md={2}>
												<Select
													id={`Quantity${id + 1}`}
													labelText='Quantity Needed'
													onChange={this.handlePartQuantityChange(id)}
												>
													<option value=''></option>
													<option value='1'>1</option>
													<option value='2'>2</option>
													<option value='3'>3</option>
													<option value='4'>4</option>
													<option value='5'>5</option>
													<option value='6'>6</option>
													<option value='7'>7</option>
													<option value='8'>8</option>
													<option value='9'>9</option>
													<option value='10'>10</option>
													<option value='11'>11</option>
													<option value='12'>12</option>
													<option value='13'>13</option>
													<option value='14'>14</option>
													<option value='15'>15</option>
													<option value='16'>16</option>
													<option value='17'>17</option>
													<option value='18'>18</option>
													<option value='19'>19</option>
													<option value='20'>20</option>
												</Select>
											</Column>

											<Column xs={12} md={2}>

												{
													id === 0 ? (
														<button
															type='button'
															className='add-remove-button'
															onClick={this.handleAddPartItem}
															data-id={id}
														>
															<MdAddCircle />
															Add Part
														</button>
													) : (
														<button
															type='button'
															className='add-remove-button'
															onClick={this.handleRemovePartItem(id)}
															data-id={id}
														>
															<MdRemoveCircle />
																Remove Part
														</button>
													)
												}
											</Column>
										</Row>

									))}

									{/* End Parts */}

									{
										this.state.rblServiceType === 'Service' &&
										<>
											<TextArea
												id='txtSpecialRequests'
												labelText='Special accommodations or requests'
												rows={3}
												onChange={this.onInputChange}
											/>

											<p>Please Note: If a customer has window/door treatments that need to be removed, please ask that the customer remove these prior to service.</p>
										</>
									}


									<Row verticalGutters={true}>
										<Column xs={12} md={6}>
											<p>To select multiple files, hold CTRL while you are making your selections. Uploads are limited to 10 MB total.</p>

											<TextInput
												type='file'
												id='fuAttachment'
												name='fuAttachment'
												labelText='Upload attachments or pictures'
												className='form-control-file'
												multiple={true}
												onChange={this.onInputChange}
											/>
										</Column>

										{
											this.state.rblDeliverTo === 'Other Location' &&
											this.state.dvGroundCheck === 'Yes' &&
											<p className='error-text'>*Ground shipping exceptions include: any part/piece 96&quot; or greater, door panels, door screens, raw glass and full units.</p>
										}
									</Row>
								</>
							}

							{
								this.state.rblServiceType &&
								<div className='sf-text--center'>
									<hr />

									<Button
										color='black'
										size='sm'
										rounded={true}
										onClick={this.onFormSubmit}
									>
										Submit
									</Button>
								</div>
							}
						</form>
					</div>
				</Container>
			</Layout >
		);
	}
}

SwatRequest.propTypes = {
	data: PropTypes.object
};

export default SwatRequest;
