import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '@pella/sash-foundation/dist/container';
import Row from '@pella/sash-foundation/dist/row';
import Column from '@pella/sash-foundation/dist/column';
import List from '@pella/sash-foundation/dist/list';
import { BaseImage, BaseLink } from '../../../components';

import './footer.scss';

const Footer = (props) => {
	const staticData = useStaticQuery(query).contentstackFooter2021;

	return (
		<footer className='coop-footer'>
			<Container fluid={true}>
				{staticData?.columns?.[0] && (
					<Row
						verticalGutters={true}
						justify='space-between'
						className=''
					>
						{staticData?.icon?.url && (
							<Column
								xs={12}
								sm={12}
								md={4}
								className='sf-text--center coop_logo'
							>
								<BaseImage data={{ image: { url: staticData.icon.url }, image_alt: '' }} />
							</Column>
						)}

						{!props.hideLinks &&
							staticData.columns.map((col, index) => (
								<Column
									key={index}
									xs={12}
									sm={4}
									md={3}
									xl='fluid'
								>
									{col.cta?.[0] && (
										<List bullets='none'>
											{col.cta.map((item, index) => (
												<li key={index}>
													<BaseLink data={item} />
												</li>
											))}
										</List>
									)}
								</Column>
							))}

						{staticData?.copyright && (
							<Column
								xs={12}
								sm={12}
								md={4}
								xl='fluid'
							>
								<Row>
									<span
										style={{
											fontSize: '0.75rem',
											color: '#fff',
											margin: '20px 0px 16px',
											paddingRight: '3px',
										}}
									>
										&#169; {new Date().getFullYear()}
									</span>
									<span
										className='coop-footer-Copyright sf-text--center'
										dangerouslySetInnerHTML={{ __html: staticData.copyright }}
									/>
								</Row>
							</Column>
						)}
					</Row>
				)}
			</Container>
		</footer>
	);
};

Footer.propTypes = {
	TopFooter: PropTypes.bool,
	hideLinks: PropTypes.bool,
};

export default Footer;

const query = graphql`
	{
		contentstackFooter2021 {
			icon {
				url
			}
			columns {
				cta {
					...CTA
				}
			}
			copyright
		}
	}
`;
