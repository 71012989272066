import React from 'react';
import PropTypes from 'prop-types';
import { BaseImage, BaseButton } from '../../../components';
import Video from '../video';

import './index.scss';

const Feature = ({ data, className, contain, ...other }) => {
	if (!data) return null;

	return (
		<div className={`coop-feature ${data.image_position === 'right' ? 'coop-feature--right' : ''} ${data.style ? `coop-feature--${data.style}` : ''} ${className || ''}`} {...other}>
			{data.image?.[0] &&
				<div className={`coop-feature-Image ${contain ? 'coop-feature-Image--contain' : ''}`}>
					<BaseImage data={data.image[0]} lazy={true} />
				</div>
			}
			{data.video?.[0] &&
				<div className={`coop-feature-Video ${contain ? 'coop-feature-Image--contain' : ''}`}>
					<Video
						src={data.video[0].video_link.href}
						imageSrc={data.video[0].overlay_image?.[0]}
						overlay={data.video[0].overlay_image?.[0] ? true : false}
					/>
				</div>
			}

			<div className='coop-feature-Content'>
				{data.heading &&
					<h2>{data.heading}</h2>
				}
				{data.description &&
					<div
						className='coop-feature-Description'
						dangerouslySetInnerHTML={{ __html: data.description }}
					/>
				}
				{data.cta?.[0] &&
					<BaseButton data={data.cta[0]} className='coop-feature-Btn' />
				}
			</div>
		</div>
	);
};

Feature.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
	contain: PropTypes.bool
};

export default Feature;
