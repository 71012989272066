import React, { Component } from "react";
import PropTypes from "prop-types";
import { Column, Row, Button } from '@pella/sash-foundation';
import Slider from "react-slick";
import HtmlParser from "react-html-parser";
import Image from '../images/image';
import './index.scss';
import AnimateHeight from 'react-animate-height';

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			onClick={onClick}
		>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick} = props;
	return (
		<div
			className={className}
			onClick={onClick}
		/>
	);
}

class SlickCarousel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggleCaurosal: false,
			height: 0
		};
	}
	static defaultProps = {
		dots: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		className: "slider variable-width",
	}
	toggleVideoHandler = () => {
		this.setState(prevState => ({
			toggleCaurosal: !prevState.toggleCaurosal,
			height: prevState.height === 0 ? 'auto' : 0
		}));
	}

	render() {
		var settings = {
			infinite: true,
			speed: 500,
			slidesToShow: this.props.slidesToShow,
			slidesToScroll: this.props.slidesToScroll,
			arrows: true,
			accessibility: true,
			useCSS: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />
		};

		const { data, asideWrapper=false } = this.props;
		const {
			carousels
		} = data;
		const { images } = data.carousel;

		return (
			<div className='fno-carousel-content option-carousel-content sf-mb--2xl'>
				<Row className='justify-content-end'>
					<Column md={5} xs={12}>
						{
							data.header &&
							<p className='h4 xs-mt--0' role='heading' aria-level='4'>
								{HtmlParser(data.header)}
							</p>
						}
						{
							data.aside_content &&
							<div className='aside-content'>
								{
									asideWrapper &&
										<p>{HtmlParser(data.aside_content)}</p>
									|| HtmlParser(data.aside_content)

								}
							</div>
						}
						{
							data.footnote &&
							<div className='fonote'>
								{HtmlParser(data.footnote)}
							</div>
						}
					</Column>
					{
						images &&
						images.length &&
						<Column md={7} xs={12} className='sf-text--center'>
							<div className='carousal-wrapper'>
								{
									(data.carousel.carousel_label) &&
									<p className='h5' role='heading' aria-level='5'>
										{HtmlParser(data.carousel.carousel_label)}
									</p>
								}
								{
									images.length > 3 &&
									<Slider {...settings}>
										{
											images.map((item, index) => {
												return <React.Fragment key={index}>
													<Image
														data={item}
														className='sf-width--100 fno-slide-img'
													/>
													<div className='Caption' >{HtmlParser(item.image_title)}</div>
												</React.Fragment>;
											})
										}
									</Slider> ||
									<div className='all-images sf-display--flex sf-py--md no-carousel' style={{ flexFlow: 'row wrap' }}>
										{
											images.map((item, index) => {
												return <div className='sf-px--sm sf-mb--sm collpase-img-col sf-text--center' key={index}>
													<Image
														data={item}
														className='sf-width--100 fno-slide-img'
													/>
													<small className='imageCaption sf-mt--sm justify'>
														{HtmlParser(item.image_title)}
													</small>
												</div>;
											})
										}
									</div>
								}
							</div>
						</Column>
					}
				</Row>
				{
					images &&
					images.length > 3 &&
					<div className='collapsible'>
						<div className='sf-text--right sf-mt--md'>
							<Button className='toggle-btn' color='gray-60' outline onClick={() => this.toggleVideoHandler()}>
								{(this.state.toggleCaurosal) ? `Close X` : `View All ${images.length} options`}
							</Button>
						</div>
						<AnimateHeight
							duration={400}
							height={this.state.height}
						>
							<div className='allImages sf-display--flex' style={{ flexFlow: 'row wrap' }}>
								{
									images &&
									images.length &&
									images.map((item, index) => (
										<div className='sf-px--sm sf-mb--sm collpase-img-col sf-text--center' key={index}>
											<Image
												data={item}
												className='sf-width--100 fno-slide-img graphic'
											/>
											<small className='imageCaption sf-mt--sm justify'>{HtmlParser(item.image_title)}</small>
										</div>
									))
								}
							</div>
						</AnimateHeight>
					</div>
				}
			</div>
		);
	}
}
SlickCarousel.propTypes = {
	data: PropTypes.object,
};

export default SlickCarousel;
