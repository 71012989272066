import React, { useState, useEffect } from 'react';
import ReactTooltip from "react-tooltip";
import axios from 'axios';

import { AiOutlineInfoCircle, AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import { Container, Checkbox, Alert } from '@pella/sash-foundation';

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import smoothScroll from '../../../components/common/smooth-scroll';

import SwatStoreForm from '../../../components/page/swatrequest/store-form';
import SwatJobContactForm from '../../../components/page/swatrequest/job-contact-form';
import SwatServiceForm from '../../../components/page/swatrequest/service-form';
import SwatRoomForm from '../../../components/page/swatrequest/room-form';
import SwatFloorForm from '../../../components/page/swatrequest/floor-form';
import SwatPartsForm from '../../../components/page/swatrequest/parts-form';
import SwatUploadForm from '../../../components/page/swatrequest/upload-form';
import SwatCommentsForm from '../../../components/page/swatrequest/comments-form';

import { validateStoreForm } from '../../../components/page/swatrequest/validation';


import './index.scss';

const SWATRequestForm = () => {

	const [formType, setFormType] = useState('');
	const [formTypeSelected, setFormTypeSelected] = useState(false);
	const [activeItem, setActiveItem] = useState([]);
	const [allExpanded, setAllExpanded] = useState(false);
	const [errors, setErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [update, setUpdate] = useState(true);
	const [successType, setSuccessType] = useState("");

	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const phoneReplace = /\(|\)|\-|\_| /g;
	const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
	const phoneRegex1 = /^[1-9]([\.\s\-]?\d){3,6}$/;
	// const zipcodeRegex = /^\d{5}$|^\d{5}-\d{4}$/;
	const zipcodeRegex = /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/;

	const [values, setValues] = useState({
		requestType: '',
		storeNameNumber: '',
		storeContactName: '',
		bestContactNumber: '',
		storeContactEmail: '',
		jobNumber: '',
		originalPONumber: '',
		newPONumber: '',
		lineNumberAffected: '',
		issueDesc: '',
		bestTextNumber: '',
		jobContactName: '',
		jobName: '',
		jobStreet: '',
		jobStreet2: '',
		jobCity: '',
		jobState: '',
		jobZip: '',
		jobBestContactNumber: '',
		jobBestContactEmail: '',
		jobTextNumber: '',
		serviceNeed: '',
		roomDescription: [],
		roomDescOther: '',
		floorLevel: [],
		parts: [],
		attachment: [],
		comments: '',
		deliverParts: '',
		shippingName: '',
		shippingAddress: '',
		shippingCity: '',
		shippingState: '',
		shippingZip: '',
		shippingPhone: '',
	});

	const [partsArray, setPartsArray] = useState([{ description: '', quantity: '', storeOrdered: '' }]);

	useEffect(() => {
		if (activeItem.length === 0 && formTypeSelected) {
			setActiveItem('StoreInfo');
		}

	}, [activeItem, formTypeSelected]);

	// useEffect(() => {
	// 	setSuccess(false);
	// }, [values]);

	useEffect(() => {
		smoothScroll.scrollTo(`${activeItem}`, -25);
	}, [activeItem]);

	useEffect(() => {
		if (update) {
			setUpdate(false);
		}
	}, [update]);

	// useEffect(() => {
	// 	console.log(values);
	// });

	const handleFormType = (type) => {
		setFormType(type);
		setFormTypeSelected(true);
		setValues({ ...values, requestType: type });
	};

	const expandAll = () => {
		setActiveItem(['StoreInfo', 'JobInfo', 'ServiceInfo', 'RoomInfo', 'FloorInfo', 'PartsInfo', 'Attachments', 'Comments']);
		setAllExpanded(true);
	};

	const collapseAll = () => {
		setActiveItem([]);
		setAllExpanded(false);
		setFormTypeSelected(false);
	};

	const validateStoreInfo = () => {
		let data = [];
		data.storeNameNumber = values.storeNameNumber;
		data.storeContactName = values.storeContactName;
		data.bestContactNumber = values.bestContactNumber;
		data.storeContactEmail = values.storeContactEmail;
		data.jobName = values.jobName;
		data.originalPONumber = values.originalPONumber;

		if (values.requestType === 'PartsOnly') {
			data.issueDesc = values.issueDesc;
		}

		if (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') {
			data.deliverParts = values.deliverParts;
			if (values.deliverParts === 'Other Location') {
				data.shippingName = values.shippingName;
				data.shippingAddress = values.shippingAddress;
				data.shippingCity = values.shippingCity;
				data.shippingState = values.shippingState;
				data.shippingZip = values.shippingZip;
			}
		}

		return validateStoreForm(data);
	};

	const finalValidation = () => {

		let totalErrors = [];
		let totalSize = 0;

		if (values.attachment.length > 0) {
			if (values.attachment.length === 1) {
				if ((values.attachment[0].size / 1024 / 1024) > 10) {
					totalErrors.push({ attachment: "Upload size is greater than 10MB" });
					if (allExpanded) {
						smoothScroll.scrollTo('Attachments', -25);
					}
					else {
						setActiveItem('Attachments');
					}
				}
			}
			else if (values.attachment.length > 1) {
				Object.entries(values.attachment).forEach((file) => {
					totalSize = totalSize + (file[1].size / 1024 / 1024)
				});

				if (totalSize > 10) {
					totalErrors.push({ attachment: "Total upload size is greater than 10MB" });
					if (allExpanded) {
						smoothScroll.scrollTo('Attachments', -25);
					}
					else {
						setActiveItem('Attachments');
					}
				}
			}
		}
		if (values.floorLevel.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('FloorInfo', -25);
			}
			else {
				setActiveItem('FloorInfo');
			}
			totalErrors.push({ floorLevel: "Floor Level is required" });
		}
		if (values.roomDescription.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('RoomInfo', -25);
			}
			else {
				setActiveItem('RoomInfo');
			}
			totalErrors.push({ roomDescription: "Room Description is required" });
		}
		if (values.jobZip.length !== 0 && values.jobZip.match(zipcodeRegex) === null) {
			if (allExpanded) {
				smoothScroll.scrollTo('JobInfo', -25);
			}
			else {
				setActiveItem('JobInfo');
			}
			totalErrors.push({ jobZip: "Zipcode is invalid" });
		}
		if (values.jobBestContactNumber.replace(phoneReplace, '').length !== 10 && values.jobBestContactNumber.replace(phoneReplace, '').length !== 7 && values.jobBestContactNumber.replace(phoneReplace, '').length !== 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('JobInfo', -25);
			}
			else {
				setActiveItem('JobInfo');
			}
			totalErrors.push({ jobBestContactNumber: 'Phone is not a valid phone number' });
		}
		if (values.jobBestContactEmail.length !== 0 && values.jobBestContactEmail.match(emailRegex) === null) {
			if (allExpanded) {
				smoothScroll.scrollTo('JobInfo', -25);
			}
			else {
				setActiveItem('JobInfo');
			}
			totalErrors.push({ jobBestContactEmail: "Please enter a valid Email Address" });
		}
		if (values.jobTextNumber.replace(phoneReplace, '').length !== 10 && values.jobTextNumber.replace(phoneReplace, '').length !== 7 && values.jobTextNumber.replace(phoneReplace, '').length !== 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('JobInfo', -25);
			}
			else {
				setActiveItem('JobInfo');
			}
			totalErrors.push({ jobTextNumber: 'Phone is not a valid phone number' });
		}
		if (values.storeNameNumber.length === 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ storeNameNumber: "Store Name and Number is required" });
		}
		if (values.storeContactName.length === 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ storeContactName: "Store Contact Number is required" });
		}
		if (values.bestContactNumber.replace(phoneReplace, '').length !== 10 && values.bestContactNumber.replace(phoneReplace, '').length !== 7) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ bestContactNumber: 'Phone is not a valid phone number' });
		}
		if (values.bestContactNumber.replace(phoneReplace, '').match(phoneRegex) === null) {
			if (values.bestContactNumber.replace(phoneReplace, '').match(phoneRegex1) === null) {
				if (allExpanded) {
					smoothScroll.scrollTo('StoreInfo', -25);
				}
				else {
					setActiveItem('StoreInfo');
				}
				totalErrors.push({ bestContactNumber: 'Please enter a valid phone number' });
			}
		}
		if (values.storeContactEmail.length === 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ storeContactEmail: "Store Contact Email is required" });
		}
		if (values.storeContactEmail.match(emailRegex) === null && values.storeContactEmail.length !== 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ storeContactEmail: "Please enter a valid Email Address" });
		}
		if (values.jobName.length === 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobName: "Job Name is required" });
		}
		if (values.originalPONumber.length === 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ originalPONumber: "Original PO Number is required" });
		}

		if (values.jobContactName.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobContactName: "Jobsite Contact Name is required" });
		}

		if (values.jobStreet.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobStreet: "Street is required" });
		}

		if (values.jobCity.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobCity: "City is required" });
		}

		if (values.jobState.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobState: "State is required" });
		}

		if (values.jobZip.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobZip: "Zip Code required" });
		}

		if (values.jobZip.match(zipcodeRegex) === null && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobZip: "Zipcode is invalid" });
		}

		if (values.requestType !== 'PartsOnly' && values.jobBestContactNumber.replace(phoneReplace, '').length !== 10 && values.jobBestContactNumber.replace(phoneReplace, '').length !== 7 && values.jobBestContactNumber.replace(phoneReplace, '').length !== 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobBestContactNumber: 'Phone is not a valid phone number' });
		}

		if (values.requestType !== 'PartsOnly' && values.jobBestContactNumber.replace(phoneReplace, '').match(phoneRegex) === null) {
			if (values.jobBestContactNumber.replace(phoneReplace, '').match(phoneRegex1) === null) {
				if (allExpanded) {
					smoothScroll.scrollTo('StoreInfo', -25);
				}
				else {
					setActiveItem('StoreInfo');
				}
				totalErrors.push({ jobBestContactNumber: 'Please enter a valid phone number' });
			}
		}

		if (values.jobBestContactEmail.length === 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobBestContactEmail: "Best Contact Email required" });
		}

		if (values.jobBestContactEmail.match(emailRegex) === null && values.jobBestContactEmail.length !== 0 && values.requestType !== 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ jobBestContactEmail: "Please enter a valid Email Address" });
		}

		if (values.issueDesc.length === 0 && values.requestType === 'PartsOnly') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ issueDesc: "Description of Issue is required" });
		}

		if (values.deliverParts.length === 0 && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService')) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ deliverParts: "Please Select Delivery Option" });
		}

		if (values.shippingName.length === 0 && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') && values.deliverParts === 'Other Location') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingName: "Shipping Name is required" });
		}
		if (values.shippingAddress.length === 0 && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') && values.deliverParts === 'Other Location') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingAddress: "Shipping Address is required" });
		}
		if (values.shippingCity.length === 0 && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') && values.deliverParts === 'Other Location') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingCity: "Shipping City is required" });
		}
		if (values.shippingState.length === 0 && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') && values.deliverParts === 'Other Location') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingState: "Shipping State is required" });
		}
		if (values.shippingZip.length === 0 && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') && values.deliverParts === 'Other Location') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingZip: "Shipping Zipcode is required" });
		}
		if (values.shippingZip.match(zipcodeRegex) === null && (values.requestType === 'PartsOnly' || values.requestType === 'PartsWithService') && values.deliverParts === 'Other Location') {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingZip: "Zipcode is invalid" });
		}
		if (values.shippingPhone.replace(phoneReplace, '').length !== 10 && values.shippingPhone.replace(phoneReplace, '').length !== 7 && values.shippingPhone.replace(phoneReplace, '').length !== 0) {
			if (allExpanded) {
				smoothScroll.scrollTo('StoreInfo', -25);
			}
			else {
				setActiveItem('StoreInfo');
			}
			totalErrors.push({ shippingPhone: 'Phone is not a valid phone number' });
		}
		if (totalErrors.length !== 0) {
			let finalErrors = Object.assign({}, ...totalErrors);
			expandAll();
			return finalErrors;
		}
		else {
			return true;
		}
	};

	const handleViewSection = (nextItem) => {

		if (activeItem.includes('StoreInfo')) {

			const validateStore = validateStoreInfo();

			if (Object.keys(validateStore).length > 0) {
				setErrors(validateStore);
				setHasErrors(true);
			} else {
				setErrors({});
				setHasErrors(false);
				setActiveItem([nextItem]);
			}
		}
		else if (activeItem.includes('RoomInfo')) {
			if (values.roomDescription.length > 0) {
				setErrors({});
				setHasErrors(false);
				setActiveItem([nextItem]);
			} else {
				setErrors({ ...errors, roomDescription: 'Please select a room' });
				setHasErrors(true);
			}
		}
		else if (activeItem.includes('FloorInfo')) {
			if (values.floorLevel.length > 0) {
				setErrors({});
				setHasErrors(false);
				setActiveItem([nextItem]);
			} else {
				setErrors({ ...errors, floorLevel: 'Please select a floor' });
				setHasErrors(true);
			}
		}
		else {
			setActiveItem([nextItem]);
		}
	};

	const handleInputChange = (event) => {
		const field = event.target.name;

		setValues({ ...values, [field]: event.target.value });
		setSuccess(false);
		setSuccessType('')
	};

	const onBlur = (event) => {
		const field = event.target.name;
		if (errors[field]) {
			setErrors({ ...errors, [field]: '' });
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		if (finalValidation() == true) {
			if (values.roomDescOther !== '') {
				let newArray = [...values.roomDescription];

				if (newArray.includes(values.roomDescOther)) {
					newArray = newArray.filter(value => value !== values.roomDescOther);
					setValues({ ...values, roomDescription: newArray });
				} else {
					newArray = [...newArray, values.roomDescOther];
					setValues({ ...values, roomDescription: newArray });
				}
			}

			const formattedData = {
				date: Date.now(),
				request_type: values.requestType,
				store_name_number: values.storeNameNumber,
				store_contact_name: values.storeContactName,
				best_contact_number: values.bestContactNumber,
				store_contact_email: values.storeContactEmail,
				job_number: values.jobNumber,
				original_po_number: values.originalPONumber,
				new_po_number: values.newPONumber,
				issueDesc: values.issueDesc,
				line_number_affected: values.lineNumberAffected,
				best_text_number: values.bestTextNumber,
				job_contact_name: values.jobContactName,
				job_name: values.jobName,
				job_street: values.jobStreet,
				job_street_2: values.jobStreet2,
				job_city: values.jobCity,
				job_state: values.jobState,
				job_zip: values.jobZip,
				job_best_contact_number: values.jobBestContactNumber,
				job_best_contact_email: values.jobBestContactEmail,
				job_text_number: values.jobTextNumber,
				service_need: values.serviceNeed,
				room_description: values.roomDescription,
				floor_level: values.floorLevel,
				parts: partsArray,
				comments: values.comments,
				deliverParts: values.deliverParts,
				shippingName: values.shippingName,
				shippingAddress: values.shippingAddress,
				shippingCity: values.shippingCity,
				shippingState: values.shippingState,
				shippingZip: values.shippingZip,
				shippingPhone: values.shippingPhone,
			};

			const emailData = new FormData();

			if (values.attachment[0] !== null) {
				for (let i = 0; i < values.attachment.length; i++) {
					emailData.append('file', values.attachment[i]), values.attachment[i].name;
				}
				emailData.append('data', JSON.stringify(formattedData));
			} else {
				emailData.append('data', JSON.stringify(formattedData));
			}

			axios.post(`${process.env.GATSBY_PELLA_API}/pella-coop/v1/email/swat-form`, emailData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization:
						'Bearer ' + process.env.GATSBY_PELLA_API_TOKEN
				}
			}).then((response) => {
				if (response.data.success === true) {
					window.scrollTo(0, 0);
					setDisabled(true);
					setSuccessType(values.requestType)
					setValues({
						requestType: '',
						storeNameNumber: '',
						storeContactName: '',
						bestContactNumber: '',
						storeContactEmail: '',
						jobNumber: '',
						originalPONumber: '',
						newPONumber: '',
						issueDesc: '',
						lineNumberAffected: '',
						bestTextNumber: '',
						jobContactName: '',
						jobName: '',
						jobStreet: '',
						jobStreet2: '',
						jobCity: '',
						jobState: '',
						jobZip: '',
						jobBestContactNumber: '',
						jobBestContactEmail: '',
						jobTextNumber: '',
						serviceNeed: '',
						roomDescription: [],
						roomDescOther: '',
						floorLevel: [],
						parts: [],
						attachment: [],
						comments: '',
						deliverParts: '',
						shippingName: '',
						shippingAddress: '',
						shippingCity: '',
						shippingState: '',
						shippingZip: '',
						shippingPhone: '',
					});
					setSuccess(true);
					setUpdate(true);
				}
				// console.log('response :>> ', response);
			}).catch((error) => {
				console.log('error :>> ', error);
			});
		}
		else {
			let finalError = finalValidation();
			setSubmitError(true);
			setDisabled(true);
			setErrors(finalError);
		}
	};

	return (
		<Layout>
			<SEO title='ProDealer Warranty Request Form' />
			{/* <Nav /> */}
			<Container>
				{
					success && successType === 'Diagnose' &&
					<Alert type='success' hideClose={true}>Your form has been successfully submitted. A Pella team member will be reaching out within 2 business days to schedule time for a virtual diagnosis</Alert>
				}
				{
					success && (successType === 'PartsOnly' || successType === 'PartsWithService') &&
					<Alert type='success' hideClose={true}>Your form has been successfully submitted. </Alert>
				}

			</Container>
			<div className='content-wrapper sf-my--lg service-request-form'>
				<div className='container-wrapper'>
					<div>
						<h1>ProDealer Warranty Request Form</h1>
					</div>
					<div>
						<h2>Select What Is Needed</h2>
						<fieldset className='sf-formGroup service-type'>
							<legend className='sf-display--block required'>Please select one</legend>
							<div className='check-box-info-row'>
								<Checkbox
									type='radio'
									id='formType_1'
									labelText='Diagnose'
									value='Diagnose'
									group='formType'
									onChange={(event) => handleFormType(event.target.value)}
								/>
								<a
									data-for="diagnose"
									data-tip="Use this if you are unsure of product, or service that needs to be performed.<br />This option requires an on site representative to perform a virtual visit."
									data-iscapture="true"
								>
									<AiOutlineInfoCircle />
								</a>
								{/* <a
									role='button'
									title='Use this if you are unsure of product, or service that needs to be performed. This option requires an on site representative to perform a virtual visit.'
									tabIndex='0'
									data-tip='custom show'
									data-for='diagnose'
									data-event='click focus'
								>
									<AiOutlineInfoCircle />
								</a>  */}
							</div>
							<div className='check-box-info-row'>
								<Checkbox
									type='radio'
									id='formType_2'
									labelText='Parts with service'
									value='PartsWithService'
									group='formType'
									onChange={(event) => handleFormType(event.target.value)}
								/>
								<a
									data-for="service"
									data-tip="Select this option if service and parts are needed to correct warranty related concerns."
									data-iscapture="true"
								>
									<AiOutlineInfoCircle />
								</a>
								{/* <a
									role='button'
									title='Select this option if service and parts are needed to correct warranty related concerns.'
									tabIndex='0'
									data-tip='custom show'
									data-for='service'
									data-event='click focus'
								>
									<AiOutlineInfoCircle />
								</a>  */}
							</div>
							<div className='check-box-info-row'>
								<Checkbox
									type='radio'
									id='formType_3'
									labelText='Parts/Pieces only'
									value='PartsOnly'
									group='formType'
									onChange={(event) => handleFormType(event.target.value)}
								/>
								<a
									data-for="parts"
									data-tip="Select this option if you know what parts need to be ordered and no service is necessary."
									data-iscapture="true"
								>
									<AiOutlineInfoCircle />
								</a>
								{/* <a
									role='button'
									title='Select this option if you know what parts need to be ordered and no service is necessary.'
									tabIndex='0'
									data-tip='custom show'
									data-for='parts'
									data-event='click focus'
								>
									<AiOutlineInfoCircle />
								</a>  */}
							</div>
						</fieldset>
						{formType &&
							<div className='sf-mb--lg'>
								{allExpanded ?
									<div className='expand-collapse-button' role='button' onClick={() => collapseAll()}><span><AiFillCaretDown /></span><span>Collapse All</span> </div>
									// <Button color='gray-80' size='sm' outline onClick={() => collapseAll()}>Collapse All</Button>
									:
									<div className='expand-collapse-button' role='button' onClick={() => expandAll()}><span><AiFillCaretRight /></span><span>Expand All</span></div>
									// <Button color='gray-80' size='sm' outline onClick={() => expandAll()}>Expand All</Button>
								}
							</div>
						}
					</div>
					{formType &&
						<form onSubmit={(event) => handleSubmit(event)}>
							<div id='StoreInfo' className='accordion-top' onClick={() => activeItem.includes('StoreInfo') ? handleViewSection('') : handleViewSection('StoreInfo')}>
								{activeItem.includes('StoreInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
								<h2>Store Information</h2>
							</div>
							{activeItem.includes('StoreInfo') &&
								<div className='accordion-content'>
									<SwatStoreForm
										values={values}
										errors={errors}
										expanded={allExpanded}
										handleViewSection={handleViewSection}
										handleInputChange={handleInputChange}
										onBlur={onBlur} />
								</div>
							}
							{formType !== 'PartsOnly' &&
								<>
									<div id='JobInfo' className='accordion-top' onClick={() => activeItem.includes('JobInfo') ? handleViewSection('') : handleViewSection('JobInfo')}>
										{activeItem.includes('JobInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
										<h2>Jobsite Contact Information</h2>
									</div>
									{activeItem.includes('JobInfo') &&
										<div className='accordion-content'>
											<SwatJobContactForm
												values={values}
												errors={errors}
												expanded={allExpanded}
												handleViewSection={handleViewSection}
												handleInputChange={handleInputChange}
												formType={formType}
												onBlur={onBlur} />
										</div>
									}
								</>
							}
							{formType === 'PartsWithService' &&
								<>
									<div id='ServiceInfo' className='accordion-top' onClick={() => activeItem.includes('ServiceInfo') ? handleViewSection('') : handleViewSection('ServiceInfo')}>
										{activeItem.includes('ServiceInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
										<h2>Service Information</h2>
									</div>
									{activeItem.includes('ServiceInfo') &&
										<div className='accordion-content'>
											<SwatServiceForm
												values={values}
												errors={errors}
												expanded={allExpanded}
												handleViewSection={handleViewSection}
												handleInputChange={handleInputChange}
											/>
										</div>
									}
								</>
							}
							{formType !== 'PartsOnly' &&
								<>
									<div id='RoomInfo' className='accordion-top' onClick={() => activeItem.includes('RoomInfo') ? handleViewSection('') : handleViewSection('RoomInfo')}>
										{activeItem.includes('RoomInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
										<h2>Room Description</h2>
									</div>
									{activeItem.includes('RoomInfo') &&
										<div className='accordion-content'>
											<SwatRoomForm
												values={values}
												errors={errors}
												expanded={allExpanded}
												setValues={setValues}
												handleViewSection={handleViewSection}
												handleInputChange={handleInputChange}
												formType={formType}
											/>
										</div>
									}
									<div id='FloorInfo' className='accordion-top' onClick={() => activeItem.includes('FloorInfo') ? handleViewSection('') : handleViewSection('FloorInfo')}>
										{activeItem.includes('FloorInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
										<h2>Floor Level</h2>
									</div>
									{activeItem.includes('FloorInfo') &&
										<div className='accordion-content'>
											<SwatFloorForm
												values={values}
												errors={errors}
												expanded={allExpanded}
												setValues={setValues}
												handleViewSection={handleViewSection}
												handleInputChange={handleInputChange}
												formType={formType}
											/>
										</div>
									}
								</>
							}
							{formType === 'PartsWithService' &&
								<>
									<div id='PartsInfo' className='accordion-top' onClick={() => activeItem.includes('PartsInfo') ? handleViewSection('') : handleViewSection('PartsInfo')}>
										{activeItem.includes('PartsInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
										<h2>Parts Information</h2>
									</div>
									{activeItem.includes('PartsInfo') &&
										<div className='accordion-content'>
											<SwatPartsForm
												values={values}
												expanded={allExpanded}
												setValues={setValues}
												partsArray={partsArray}
												setPartsArray={setPartsArray}
												handleViewSection={handleViewSection}
											/>
										</div>
									}
								</>
							}
							{formType === 'PartsOnly' &&
								<>
									<div id='PartsInfo' className='accordion-top' onClick={() => activeItem.includes('PartsInfo') ? handleViewSection('') : handleViewSection('PartsInfo')}>
										{activeItem.includes('PartsInfo') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
										<h2>Parts Information</h2>
									</div>
									{activeItem.includes('PartsInfo') &&
										<div className='accordion-content'>
											<SwatPartsForm
												values={values}
												expanded={allExpanded}
												setValues={setValues}
												partsArray={partsArray}
												setPartsArray={setPartsArray}
												handleViewSection={handleViewSection} />
										</div>
									}
								</>
							}
							<>
								<div id='Attachments' className='accordion-top' onClick={() => activeItem.includes('Attachments') ? handleViewSection('') : handleViewSection('Attachments')}>
									{activeItem.includes('Attachments') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
									<h2>Attachments</h2>
								</div>
								{activeItem.includes('Attachments') &&
									<div className='accordion-content'>
										<SwatUploadForm
											values={values}
											expanded={allExpanded}
											errors={errors}
											setValues={setValues}
											handleViewSection={handleViewSection}
											formType={formType} />
									</div>
								}
							</>
							<>
								<div id='Comments' className='accordion-top' onClick={() => activeItem.includes('Comments') ? handleViewSection('') : handleViewSection('Comments')}>
									{activeItem.includes('Comments') ? <span><AiFillCaretDown /> </span> : <span> <AiFillCaretRight /> </span>}
									<h2>Additional Comments</h2>
								</div>
								{activeItem.includes('Comments') &&
									<div className='accordion-content'>
										<SwatCommentsForm
											values={values}
											expanded={allExpanded}
											handleViewSection={handleViewSection}
											handleInputChange={handleInputChange} />
									</div>
								}
							</>
						</form>
					}
				</div>
			</div>
			{/* <ReactTooltip id='diagnose' backgroundColor='black' textColor='white' place='top' effect='solid' multiline={true} globalEventOff='click'>
				Use this if you are unsure of product, or service that needs to be performed. <br /> This option requires an on site representative to perform a virtual visit.
			</ReactTooltip> */}
			{/* <ReactTooltip id='service' backgroundColor='black' textColor='white' place='right' effect='solid' multiline={true} globalEventOff='click'>
				Select this option if service and parts are needed to correct warranty related concerns.
			</ReactTooltip> */}
			{/* <ReactTooltip id='parts' backgroundColor='black' textColor='white' place='bottom' effect='solid' multiline={true} globalEventOff='click'>
				Select this option if you know what parts need to be ordered and no service is necessary.
			</ReactTooltip> */}

			<ReactTooltip
				id='diagnose'
				place="top"
				type="dark"
				effect="solid"
				multiline={true}
				backgroundColor='black'
				delayHide={500}
			/>
			<ReactTooltip
				id='service'
				place="right"
				type="dark"
				effect="solid"
				multiline={true}
				backgroundColor='black'
				delayHide={500}
			/>
			<ReactTooltip
				id='parts'
				place="bottom"
				type="dark"
				effect="solid"
				multiline={true}
				backgroundColor='black'
				delayHide={500}
			/>

			{/* <ReactTooltip
				id='diagnose'
				place={place}
				type={type}
				effect={effect}
				multiline={true}
          	/> */}
		</Layout>
	);
};

export default SWATRequestForm
	;
