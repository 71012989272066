import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container } from '@pella/sash-foundation';

import Layout from '../../../components/layout/layout';
import SEO from '../../../components/seo';
import Nav from '../../../components/common/nav/';
import "./index.scss";

const News = ({ data }) => {
	const page = data.contentstackLinkOnlyPage;

	return (
		<Layout>
			<SEO title={page.browser_title} />

			{/* <Nav /> */}

			<Container width={1200} className='sf-pb--2xl'>
				<h1 style={{ fontSize: '2rem', color: '#676767' }} className='sf-text--uppercase sf-text--semibold'>{page.heading}</h1>

				<table className='bulletin-table'>
					{
						page.table &&
						page.table.table_headings &&
						<thead>
							<tr>
								{
									page.table.table_headings.single_line &&
									page.table.table_headings.single_line.length > 0 &&
									page.table.table_headings.single_line.map((head, index) => (
										<th key={index}>{head}</th>
									))
								}
								<th>&nbsp;</th>
							</tr>
						</thead>
					}

					{
						page.table &&
						page.table.table_body &&
						page.table.table_body.length > 0 &&
						<tbody>
							{page.table.table_body.map((body, index) => (
								<tr key={index}>
									<td>{body.name}</td>
									<td>{body.description}</td>
									<td>{body.date}</td>
									<td>
										<a
											href={body.link[0].link.href}
											data-gtm-linktype={body.link[0].gtm_link_type}
											data-gtm-linktext={body.link[0].gtm_link_text}
											target={body.link[0].target}
											rel={body.link[0].target === '_blank' ? 'noopener noreferrer' : ''}
											title={body.link[0].ada_title}
										>
											{body.link[0].link.title}
										</a>
									</td>
								</tr>
							))}
						</tbody>
					}
				</table>
			</Container>
		</Layout>
	);
};

News.propTypes = {
	data: PropTypes.object
};

export default News;

export const query = graphql`
{
	contentstackLinkOnlyPage(id: { eq: "2a1d70d3-e60e-562f-9a12-76095d8d70d8" }) {
	  	browser_title
	  	heading

	  	table {
			table_headings {
		  		single_line
			}

			table_body {
		  		name
		  		description
		  		date
		  		link {
					...link
		  		}
			}
	  	}
	}
}
`;
