import React, { Component } from "react";
import { FaCheck } from "react-icons/fa";
import { Container, Row, Column } from '@pella/sash-foundation';

import Layout from "../../../components/layout/layout";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";

import "../change-password/index.scss";

class ChangePasswordSuccess extends Component {
	render() {
		return (
			<Layout>
				<SEO title='Change Password' />
				{/* <Nav /> */}
				<Container className='sf-pb--2xl change-password'>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>
						Change Password
					</h1>
					<hr />
					<fieldset className='sf-formGroup'>
						<legend className='sf-display--block'>Success!</legend>
						<Row>
							<Column xs='12'>
								<p>
									<span style={{ fontSize: '1.75rem', color: '#28a745' }} className='sf-text--bold sf-mr--sm'>
										<FaCheck />
									</span>{" "}
									You have successfully changed your password.
									Please click <a href='/'>here</a> to return
									to the home page.
								</p>
							</Column>
						</Row>
					</fieldset>
				</Container>
			</Layout>
		);
	}
}

export default ChangePasswordSuccess;
