import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@pella/sash-foundation/dist/container';
import List from '@pella/sash-foundation/dist/list';
import SimpleCollapse from '../../../../components/common/simple-collapse';

import './index.scss';

const FaqGroup = ({ data }) => {
	const [activeFaqTab, setActiveFaqTab] = useState(0);
	const [update, setUpdate] = useState(false);

	if (!data) return null;

	const onFaqTabClick = i => setActiveFaqTab(i);

	return (
		<div className='sf-my--lg sf-my-1030--xxl'>
			<div className='coop-support-FaqListWrapper sf-mb--lg'>
				<Container width={1530}>
					<List bullets='none' className='coop-support-FaqList'>
						{data.group.map((faq, index) => (
							<li key={index} className={activeFaqTab == index ? 'coop-support-FaqListItem--active' : null}>
								<button onClick={() => onFaqTabClick(index)} className='coop-support-FaqTab'>
									{faq.tab_name}
								</button>
							</li>
						))}
					</List>
				</Container>
			</div>

			<Container width={1530}>
				{data.group.map((ans, index) => (
					<div key={index} className={`coop-support-FaqPanl ${activeFaqTab !== index ? 'coop-displayNone' : ''}`}>
						{ans.faqs.map((item, index) => (
							<SimpleCollapse title={item.question} key={index}>
								{item.answer &&
									<div
										dangerouslySetInnerHTML={{ __html: item.answer }}
										className='sf-p--md sf-pr--xxl coop-answer'
									/>
								}
							</SimpleCollapse>
						))}
					</div>
				))}
			</Container>
		</div>
	);
};

FaqGroup.propTypes = {
	data: PropTypes.object
};

export default FaqGroup;
