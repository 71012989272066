/* eslint-disable react/prop-types */
import React, { useState, useEffect} from 'react';
import { Container, Row, Checkbox, Button } from '@pella/sash-foundation';

const SwatFloorForm = (props) => {
	const {values, setValues, handleViewSection, formType, errors, expanded} = props;
	const [nextSection, setNextSection] = useState('');


	useEffect(() => {
		if (formType === 'PartsWithService' || formType === 'PartsOnly') {
			setNextSection('PartsInfo');
		} else {
			setNextSection('Attachments');
		}
	}, [formType]);

	const handleFloorChecked = event => {
		let newArray = [...values.floorLevel];
		if (newArray.includes(event.target.value)) {
			newArray = newArray.filter(value => value !== event.target.value);
			setValues({...values, floorLevel:newArray});
		} else {
			newArray = [...newArray, event.target.value];
			setValues({...values, floorLevel:newArray});
		}
	};

	return (
		<Container>
			<Row>
				<fieldset className='sf-formGroup'>
					<legend className='sf-display--block required'>Check all that apply<span style={{ color: '#a70215' }}> *</span></legend>
					<Checkbox type='checkbox' value='Basement' group='floorLevel' labelText='Basement' onChange={(event) => handleFloorChecked(event)} checked={values.floorLevel.includes('Basement')}/>
					<Checkbox type='checkbox' value='Main Level' group='floorLevel' labelText='Main Level' onChange={(event) => handleFloorChecked(event)} checked={values.floorLevel.includes('Main Level')}/>
					<Checkbox type='checkbox' value='Second Story' group='floorLevel' labelText='Second Story' onChange={(event) => handleFloorChecked(event)} checked={values.floorLevel.includes('Second Story')}/>
					<Checkbox type='checkbox' value='All Other' group='floorLevel' labelText='All Other (Ladder / extension device required)' onChange={(event) => handleFloorChecked(event)} checked={values.floorLevel.includes('All Other')}/>
				</fieldset>
			</Row>

			{
				errors.floorLevel &&
				<Row><div className='error-text'>{errors.floorLevel}</div></Row>

			}

			{ !expanded &&
				<Row className='sf-justify-content--end'>
					<Button className='sf-mr--md' type='button' color='white' onClick={() => handleViewSection('RoomInfo')} >Previous</Button>
					<Button color='primary' type='button' onClick={() => handleViewSection(nextSection)} >Next</Button>
				</Row>
			}
		</Container>
	);
};

export default SwatFloorForm;
