import React, { useState } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";

import './index.scss';

const SimpleCollapse = ({ title = '', className, children }) => {
	const [height, setHeight] = useState(0);
	const [collapse, setCollapse] = useState(false);

	const onBtnClick = () => {
		setHeight(height === 0 ? 'auto' : 0);
		setCollapse(!collapse);
	};

	return (
		<div className={`coop-simpleCollapse ${className || ''}`}>
			<button
				className='coop-simpleCollapse-Btn'
				onClick={() => onBtnClick()}
				aria-expanded={collapse}
			>
				{title || 'Video Transcript'}
				<span className='coop-simpleCollapse-Icon'>{collapse ? '-' : '+'}</span>
			</button>

			<AnimateHeight duration={500} height={height}>
				{children}
			</AnimateHeight>
		</div>
	);
};

SimpleCollapse.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node
};

export default SimpleCollapse;