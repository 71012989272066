import { graphql } from "gatsby";
export { default as Layout } from './layout/layout';
export { default as SEO } from './seo';
export { default as IMAGE } from './common/images/image';
export { default as LINK } from './common/links/link';
export { default as BUTTON } from './common/buttons/button';
export { default as AnchorLinks } from './common/anchor-link/anchor';
export { default as SlickCarousel } from "./common/slick-carousel";
export { default as VideoOverlayImage } from './common/video-overlay-image';
export { default as LeftNav } from './page/left-nav/menu';

export { default as BaseButton } from './common/baseButton';
export { default as BaseImage } from './common/baseImage';
export { default as BaseLink } from './common/baseLink';
export { default as Header } from './common/header';
export { default as Footer } from './common/footer';
export { default as Hero } from './common/hero';
export { default as VideoModal } from './common/video-modal';

export const isValidArray = array => Array.isArray(array) && array.length > 0;
export const isValidLink = link => isValidArray(link) && link[0].link && link[0].link.href;
export const isValidImage = image => isValidArray(image) && image[0].image && image[0].image.url;

export const query = graphql`
	fragment hero on Contentstack_hero {
		title
		hero_title
		extra_text
		subtitle
		hero_image {
			title
			url
		}
		mobile_image {
			title
			url
		}
		hero_image_alt_text
		extra_text
	}
	fragment image on Contentstack_images {
		title
		image {
			title
			url
		}
		image_alt
		image_title
	}
	fragment link on Contentstack_links {
		link {
			title
			href
		}
		gtm_link_text
		gtm_link_type
		target
		ada_title
		gtm_id
		title
	}
	fragment video on Contentstack_video {
		title
		video_link {
		  title
		  href
		}
		image_overlay {
		  title
		  image_alt
		  image {
			url
		  }
		}
		gtm_type
		anchor
		transcript
	}
	fragment left_navigation on Contentstack_left_navigation {
		level_1 {
			menu {
			  ...link
			}
			level_2 {
			  sub_menu {
				...link
			  }
			}
		}
	}
`;
