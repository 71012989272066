import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import './index.scss';

const Video = ({ title, src, imageSrc, overlay, youtube = true, id }) => {
	const [show, setShow] = useState(overlay);
	const [autoPlay, setAutoPlay] = useState(overlay);

	useEffect(() => {
		if (imageSrc?.image?.url) {
			setShow(true);
			setAutoPlay(true);
		}
		else {
			setShow(false);
			setAutoPlay(false);
		}
	}, [imageSrc]);

	src = (src.indexOf('&autoplay=true') > -1) ? src.replace("&autoplay=true", '') : src;

	return (
		<div className='coop-VideoOverlayWrapper'>
			{title &&
				<p className='h3 mb-10' role='heading' aria-level='3'><small>{title}</small></p>
			}
			<div className='coop-VideoContainer width-100'>
				{imageSrc?.image && show &&
					<div
						className='coop-VideoThumbnail'
						onClick={() => setShow(!show)}
						style={{ backgroundImage: `URL(${imageSrc.image.url})` }}
					/>
				}
				{youtube && !show &&
					<iframe
						title={title}
						className={(!show ? 'display-block' : 'display-none')}
						src={!show ? src + '&autoplay=true' : src}
						loading='lazy'
						allowFullScreen
					/>
				}
				{!youtube && !show &&
					<video
						id={id}
						className='coop-Video--html5'
						autoPlay={!show}
						muted={autoPlay}
						loop={autoPlay}
						controls
						controlsList='nodownload'
					>
						<source src={src} type='video/mp4' />
					</video>
				}
			</div>
		</div>
	);
};

Video.propTypes = {
	title: PropTypes.string,
	src: PropTypes.string,
	imageSrc: PropTypes.object,
	overlay: PropTypes.bool,
	youtube: PropTypes.bool,
	id: PropTypes.string
};

export default Video;
