import React, {useState} from 'react';
import { navigate } from 'gatsby';
import axios from "axios";
import { Container, Row, Column, TextInput, Button } from '@pella/sash-foundation';

import Layout from "../../../components/layout/layout-reset-password";
import SEO from "../../../components/seo";

const ResetPassword = () => {
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPassword2, setNewPassword2] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [showErrors, setShowErrors] = useState(false);
	const [errors, setErrors]  = useState([]);


	const handleOtp = (event) => {
		setShowSuccess(false);
		setSuccessMessage('');
		setShowErrors(false);
		setErrors('');
		setPassword(event.target.value);
	};

	const handleNewPassword = (event) => {
		setShowSuccess(false);
		setSuccessMessage('');
		setShowErrors(false);
		setErrors('');
		setNewPassword(event.target.value);
	};

	const handleNewPassword2 = (event) => {
		setShowSuccess(false);
		setSuccessMessage('');
		setShowErrors(false);
		setErrors('');
		setNewPassword2(event.target.value);
	};

	const validateForm = (
		password,
		password2,
	) => {
		let formErrors = [];

		if (password.length === 0) {
			formErrors.push("Please enter your desired password.");
		}
		if (password.length > 0) {
			// this.checkPassword(password);
			var passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
			if (!password.match(passwordCheck)) {
				formErrors.push ("Please make sure your desired password is between 8 and 15 characters in length, contains one uppercase letter, one lowercase letter, and one number.");
			}
		}
		if (password2.length === 0) {
			formErrors.push("Please confirm your desired password.");
		}
		if (password.length > 0 && password2.length > 0) {
			if (!password2.match(password)) {
				formErrors.push("Please make sure the passwords you entered match.");
			}
		}

		return formErrors;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setShowSuccess(false);
		setSuccessMessage('');
		setShowErrors(false);
		setErrors([]);

		const formErrors = validateForm(
			newPassword,
			newPassword2
		);

		if (Object.keys(formErrors).length > 0) {
			setShowErrors(true);
			setErrors(formErrors);
		} else {

			const passwordData = {
				password,
				newPassword,
				newPassword2
			};

			axios({
				method: "post",
				url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/password/verify`,

				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
				},
				data: passwordData
			}).then(response => {
				if (response.data.success === true) {
					// setSuccessMessage(response.data.message);
					// setShowSuccess(true);
					navigate('/login', { state: {
						passwordResetSuccess: true,
					}});

				} else {
					if (response.data.success === false) {
						if(response.data.error.message) {
							setErrors([response.data.error.message]);
							setShowErrors(true);
						}
						if(response.data.error.password) {
							setErrors([response.data.error.password]);
							setShowErrors(true);
						}

					}
				}
			});
		}
	};

	return (
		<Layout className='login'>
			<div className='page-topper'></div>
			<SEO title='Reset Password' />
			<Container className='body-background '>
				<Row align='center'>
					<Column xs='12'>
						<h1 className='landing-page-title'>
								Pella Dealer Portal
						</h1>
						{showSuccess &&
							<div className='success_message'>
								<p>{successMessage}</p>
							</div>
						}
						{showErrors &&
							<div className='error_message'>
								<ul className='text-left'>
									{errors.map((error, index) => (
									 <li key={index}>{error}</li>
									))}
								</ul>
							</div>
						}
						<div className='login-bg'>
							<div className='login-container'>
								<div className='login-wrapper'>
									<Row>
										<Column xs='12'>
											<h2>Reset Password</h2>
											<p>Enter the One Time Password (OTP) you received in the email and set a new password.</p>
											<p>For security reasons, please do not reuse your previous password.</p>
											<hr />
										</Column>
									</Row>
									<Row>
										<Column xs='12'>
											<form
												onSubmit={(event) => handleSubmit(event)}
											>
												<TextInput
													type='text'
													name='password'
													id='password'
													labelText='One Time Password (OTP)'
													placeholder='One Time Password (OTP)'
													onChange={handleOtp}
													required
													autocomplete='off'
												></TextInput>
												<p>
												Set Your New Password
												</p>
												<TextInput
													type='password'
													id='newPassword'
													labelText='New Password'
													placeholder='New Password'
													hideLabel
													onChange={handleNewPassword}
													required
												></TextInput>
												<TextInput
													type='password'
													id='newPassword2'
													labelText='Verify New Password'
													placeholder='Verify New Password'
													hideLabel
													onChange={handleNewPassword2}
													required
												></TextInput>
												{showSuccess &&
													<p>Continue to <a href='/login'>Login</a> and enter your new password.</p>
												}
												<Button
													type='submit'
													color='black'
													className='login-button'
													disabled={showSuccess}
												>
														Reset My Password
												</Button>
											</form>
										</Column>
									</Row>
								</div>
							</div>
						</div>
					</Column>
				</Row>
			</Container>

		</Layout>
	);
};

export default ResetPassword;
