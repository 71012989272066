import React from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import sessionStorage from "sessionstorage";

export const isLoggedIn = () => {
	const status = sessionStorage.getItem("status");
	const pellaUser = sessionStorage.getItem("pellaUser");

	if (status == "authenticated" && pellaUser) {
		var isLoggedIn = true;
		return isLoggedIn;
	}
};

const PrivateRoute = ({ component: Component, ...rest }) => {
	if (!isLoggedIn()) {
		// If the user is not logged in, redirect to the login page.
		navigate(`/account/login`);
		return null;
	}

	return <Component {...rest} />;
};

PrivateRoute.propTypes = {
	component: PropTypes.func
};
export default PrivateRoute;
