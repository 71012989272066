/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';

// import styles from './index.module.scss';
import * as styles from './index.module.scss';


const OrderProgress = (props) => {

	const { orderStatus, progressContent } = props;

	const [status, setStatus] = useState('');

	const [showStepOne, setShowStepOne] = useState({ active: false, complete: false });
	const [showStepTwo, setShowStepTwo] = useState({ active: false, complete: false });
	const [showStepThree, setShowStepThree] = useState({ active: false, complete: false });
	const [showCancelled, setShowCancelled] = useState(false);

	useEffect(() => {
		setStatus(orderStatus);
	}, [orderStatus]);


	useEffect(() => {
		if (status === 'Order Received' || status === '') {
			setShowStepOne({ active: true, complete: false });
			setShowStepTwo({ active: false, complete: false });
			setShowStepThree({ active: false, complete: false });
		}
		if (status === 'In Progress') {
			setShowStepOne({ active: false, complete: true });
			setShowStepTwo({ active: true, complete: false });
			setShowStepThree({ active: false, complete: false });
		}
		if (status === 'Shipped') {
			setShowStepOne({ active: false, complete: true });
			setShowStepTwo({ active: false, complete: true });
			setShowStepThree({ active: true, complete: false });
		}
		if (status === 'Cancelled') {
			setShowStepOne({ active: false, complete: false });
			setShowStepTwo({ active: false, complete: false });
			setShowStepThree({ active: false, complete: false });
			setShowCancelled(true);
		}
	}, [status]);

	return (
		<div className={styles.progress_container}>
			{showStepOne.active && <h3>{progressContent.status[0]?.heading}</h3>}
			{showStepTwo.active && <h3>{progressContent.status[1]?.heading}</h3>}
			{showStepThree.active && <h3>{progressContent.status[2]?.heading}</h3>}
			{showCancelled && <h3>{progressContent.status[3]?.heading}</h3>}
			<div className={styles.progress_wrapper}>

				{showStepOne.active
					?
					<div className={`${styles.progress_item} ${styles.active}`}>
						<div className={styles.dot}>
							<img src={progressContent?.status[0]?.file.url}
								alt={progressContent?.status[0]?.heading} />
						</div>
						<MediaQuery minWidth={790}>
							<div><strong>{progressContent?.status[0]?.heading}</strong></div>
						</MediaQuery>
					</div>
					: showStepOne.complete
						?
						<div className={`${styles.progress_item} ${styles.completed}`}>
							<div className={styles.dot}></div>
							<MediaQuery minWidth={790}>
								<div>{progressContent?.status[0]?.heading}</div>
							</MediaQuery>
						</div>
						:
						<div className={`${styles.progress_item}`}>
							<div className={styles.dot}></div>
							<MediaQuery minWidth={790}>
								<div>{progressContent?.status[0]?.heading}</div>
							</MediaQuery>
						</div>
				}

				{showStepTwo.active
					?
					<div className={`${styles.progress_item} ${styles.active}`}>
						<div className={styles.dot}>
							<img src={progressContent?.status[1]?.file.url}
								alt={progressContent?.status[1]?.heading} />
						</div>
						<MediaQuery minWidth={790}>
							<div><strong>{progressContent?.status[1]?.heading}</strong></div>
						</MediaQuery>
					</div>
					: showStepTwo.complete
						?
						<div className={`${styles.progress_item} ${styles.completed}`}>
							<div className={styles.dot}></div>
							<MediaQuery minWidth={790}>
								<div>{progressContent?.status[1]?.heading}</div>
							</MediaQuery>
						</div>
						:
						<div className={styles.progress_item}>
							<div className={styles.dot}></div>
							<MediaQuery minWidth={790}>
								<div>{progressContent?.status[1]?.heading}</div>
							</MediaQuery>
						</div>
				}

				{showStepThree.active
					?
					<div className={`${styles.progress_item} ${styles.active}`}>
						<div className={styles.dot}>
							<img src={progressContent?.status[2]?.file.url}
								alt={progressContent?.status[2].heading} />
						</div>
						<MediaQuery minWidth={790}>
							<div><strong>{progressContent?.status[2].heading}</strong></div>
						</MediaQuery>
					</div>
					: showStepThree.complete
						?
						<div className={`${styles.progress_item} ${styles.completed}`}>
							<div className={styles.dot}></div>
							<MediaQuery minWidth={790}>
								<div>{progressContent?.status[2].heading}</div>
							</MediaQuery>
						</div>
						:
						<div className={styles.progress_item}>
							<div className={styles.dot}></div>
							<MediaQuery minWidth={790}>
								<div>{progressContent?.status[2].heading}</div>
							</MediaQuery>
						</div>
				}
			</div>
			<div>
				{showStepOne.active &&
					<p>{progressContent?.status[0]?.subheading}</p>
				}
				{showStepTwo.active &&
					<p>{progressContent?.status[1]?.subheading} </p>
				}
				{showStepThree.active &&
					<p>{progressContent?.status[2]?.subheading} </p>
				}
				{showCancelled && <p>{progressContent?.status[3]?.subheading} </p>}
			</div>
		</div>
	);
};

export default OrderProgress;
