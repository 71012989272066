/* eslint-disable react/prop-types */
import React from 'react';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { Container, Row, Column, Checkbox, List, TextInput, Button } from '@pella/sash-foundation';

import './index.scss';

const SwatPartsForm = (props) => {
	const { values, setValues, partsArray, setPartsArray, handleViewSection, expanded } =  props;

	const handlePartChange = (index) => (event) => {
		let newArray = [...partsArray];
		newArray[index][event.target.name] = event.target.value;
		setPartsArray(newArray);
	};

	const handleAddPartItem = () => {
		setPartsArray(partsArray => [...partsArray, { description: "", quantity:"", storeOrdered:"" }]);
	};

	const handleRemovePartItem = (id) => {
		let newArray = [...partsArray];
		newArray = newArray.filter((s, partId) => id !== partId);
		setPartsArray(newArray);
		setValues({...values, parts:[partsArray]});
	};

	return (
		<Container>

			{partsArray.map((part, index) => (
				<div key={index} className='sf-mb--lg'>
					<div className='part-card'>
						<Row>
							<Column xs={12} md={6} >
								<label htmlFor={`partDescription-${index > 0 ? index : ''}`}> Part Description</label>
							</Column>
							<Column xs={12} md={6} >
								<TextInput
									id={`partDescription-${index}`}
									name='description'
									labelText='Part Description'
									value={part.description}
									onChange={handlePartChange(index)}
									hideLabel
								/>
							</Column>
						</Row>
						<Row>
							<Column xs={12} md={6} >
								<label htmlFor={`partQuantity-${index > 0 ? index : ''}`}>Quantity</label>
							</Column>
							<Column xs={12} md={6} >
								<TextInput
									id={`partQuantity-${index}`}
									name='quantity'
									labelText='Quantity'
									value={part.quantity}
									onChange={handlePartChange(index)}
									hideLabel
								/>
							</Column>
						</Row>
						<Row>
							<Column xs={12} md={6} >
								<label htmlFor='partStoreOrdered'>Ordered by Store?</label>
							</Column>

							<Column xs={12} md={6} >
								<List bullets='none' layout='inline' className='store-radio '>
									<li>
										<Checkbox
											id='storeOrdered-yes'
											group={`storeOrdered-${index}`}
											name='storeOrdered'
											labelText='Yes'
											value='yes'
											checked={part.storeOrdered === 'yes'}
											onChange={handlePartChange(index)}/>
									</li>
									<li>
										<Checkbox
											id='storeOrdered-no'
											group={`storeOrdered-${index}`}
											name='storeOrdered'
											labelText='No'
											value='no'
											checked={part.storeOrdered === 'no'}
											onChange={handlePartChange(index)}/>
									</li>
								</List>
							</Column>
						</Row>
						{partsArray.length > 1  &&
							<Row className='sf-justify-content--end sf-pr--md'>
								<div className='add-remove-button' tabIndex='0' role='button' onClick={() => handleRemovePartItem(index) }><span><AiFillMinusCircle /> </span><span>Remove</span></div>
							</Row>
						}
					</div>
				</div>
			))}
			<Row className='sf-pl--md'>
				<div className='add-remove-button' role='button' tabIndex='0' onClick={() => handleAddPartItem() }><span><AiFillPlusCircle /> </span><span>Add Another Part</span></div>
			</Row>

			{ !expanded &&
				<Row className='sf-justify-content--end'>
					<Button className='sf-mr--md' type='button' tabIndex='0' color='white' onClick={() => handleViewSection('FloorInfo')} >Previous</Button>
					<Button type='button' color='primary' onClick={() => handleViewSection('Attachments')} >Next</Button>
				</Row>
			}

		</Container>

	);
};

export default SwatPartsForm;
