import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "../../common/links/link";
import AnimateHeight from 'react-animate-height';
import "./left-nav.scss";

class LeftNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapse: false,
			height: 0
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState(state => ({
			collapse: !state.collapse,
			height: state.height === 0 ? 'auto' : 0
		}));
	}

	render() {
		const data = this.props.data;
		const url = this.props.url ? this.props.url : ' ';
		let listTitle = '';
		data.level_1 &&
			data.level_1.filter((menuItem) => {
				if (menuItem.menu && menuItem.menu[0]) {
					if (menuItem.menu[0].link.href == url ||
						menuItem.menu[0].link.href == url + '/') {
						listTitle = menuItem.menu[0].link.title;
					}
					else {
						return menuItem.level_2 &&
							menuItem.level_2.filter((subMenuItem) => {
								if ((subMenuItem.sub_menu[0].link.href == url)) {
									listTitle = subMenuItem.sub_menu[0].link.title;
								}
							});
					}
				}
			});


		return (
			<nav className='secondary-nav-container'>
				<div className='nav-wrap custom-collpase mb-20'>
					<button
						className='dropdown-trigger mt-30 mobile-nav display-block display-790-none'
						onClick={this.toggle}
					>
						<span className='nav-title'>{listTitle}</span>
						<em className='gn-chevron'></em>
					</button>
					<AnimateHeight
						duration={500}
						height={this.state.height}
						className='leftnav-collapse'
					>
						<ul className='nav-ul m-0 p-0'>
							{
								data.level_1.map((menuItem) =>
									menuItem &&
									menuItem.menu[0] &&
									menuItem.menu[0].link.title &&
									<li className={
										(menuItem.menu[0].link.href === url) ||
											(menuItem.menu[0].link.href == url + '/')
											? 'active' +
											(url.indexOf(menuItem.menu[0].link.href) != -1 &&
												menuItem.level_2 &&
												menuItem.level_2.length > 0
												? ' has-submenu'
												: '')
											: (url.indexOf(menuItem.menu[0].link.href) != -1 &&
												menuItem.level_2 &&
												menuItem.level_2.length > 0
												? 'active has-submenu'
												: '')
									}
									key={menuItem.menu[0].link.title}>
										<Link
											data={menuItem.menu[0]}
											aria-label={`Left Nav Link opens ${menuItem.menu[0].link.title}`}
											className='leftnav-menu-item'>
											{menuItem.menu[0].link.title}
										</Link>
										{
											menuItem &&
											menuItem.level_2 &&
											menuItem.level_2.length > 0 &&
											<ul className=
												{
													url.indexOf(menuItem.menu[0].link.href) >= 0
														? 'leftnav-submenu mt-15 m-0 display-block '
														: 'leftnav-submenu mt-15 m-0 display-none'
												}
											>
												{
													menuItem.level_2.map((subMenuItem) =>
														subMenuItem.sub_menu.map((subMenu) =>
															subMenu.link &&
															subMenu.link.title &&
															<li
																key={subMenu.link.title}
																className={(subMenu.link.href === url)
																	? 'active-submenu'
																	: ''}
															>
																<Link data={subMenu} aria-label={`SubMenu Link opens ${subMenu.link.title}`}>
																	{subMenu.link.title}
																</Link>
															</li>
														)
													)
												}
											</ul>
										}
									</li>
								)
							}
						</ul>
					</AnimateHeight>
				</div>
			</nav>
		);
	}
}

LeftNav.propTypes = {
	data: PropTypes.object,
	url: PropTypes.string,
};

export default LeftNav;
