import React from "react";
import PropTypes from "prop-types";

const BaseLink = ({ data, children, className, ...other }) => {
	if (!data || !data.link?.[0])
		return null;

	const {
		link = [],
		cta_title,
		gtm_link_type,
		gtm_link_text,
		ada_title,
		aria,
		target,
		image = {}
	} = data;

	return (
		<a
			href={link[0].destination}
			title={ada_title}
			data-gtm-linktype={gtm_link_type}
			data-gtm-linktext={gtm_link_text}
			target={target}
			rel={target === '_blank' ? 'noopener noreferrer' : null}
			className={className || null}
			aria-label={aria}
			{...other}
		>
			{image?.url && <img src={image.url} alt='' className='sf-mr--sm' />}
			{children || cta_title || link[0].default_title || ''}
		</a>
	);
};

BaseLink.propTypes = {
	data: PropTypes.object,
	children: PropTypes.node,
	className: PropTypes.string
};

export default BaseLink;