import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const BaseImage = ({ data, lazy = true, placeholder, params = '', className, ...other }) => {
	if (!data || !data.image)
		return null;

	const {
		image = {},
		image_alt = '',
		image_title
	} = data;

	const checkExtension = image.url.match(/.(png|gif|pjpg|webp|svg)$/i) ? true : false;
	const lowresPlaceholder = <img src={`${image.url}${!checkExtension ? '?format=pjpg&auto=webp&brightness=100' : '?brightness=100'}`} alt='' className={className || ''} />;

	const renderImage = () => (
		<img
			src={image.url + `${!checkExtension ? '?format=pjpg&auto=webp' : ''}${params}`}
			alt={image_alt}
			title={image_title}
			className={className || ''}
			{...other}
		/>
	);

	return (
		lazy ? (
			<>
				<LazyLoad once={true} placeholder={placeholder || lowresPlaceholder} offset={600}>
					{renderImage()}
				</LazyLoad>
				<noscript>
					{renderImage()}
					<style dangerouslySetInnerHTML={{ __html: `.lazyload-placeholder { display: none; }` }} />
				</noscript>
			</>
		) : renderImage()
	);
};

BaseImage.propTypes = {
	data: PropTypes.object,
	lazy: PropTypes.bool,
	placeholder: PropTypes.string,
	params: PropTypes.string,
	className: PropTypes.string
};

export default BaseImage;