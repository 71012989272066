import { useState } from 'react';
import localStorage from 'localStorage';
import sessionStorage from 'sessionstorage';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Array validation - pass array in and check for length to be more than 0.
// Use: validateArray(data.array)
export const validateArray = (array) => Array.isArray(array) && array.length > 0;

// Link validation - pass link in to array validator and check for href existence.
// Use: validateLink(data.link)
export const validateLink = (link) => validateArray(link) && (link[0].link?.url || link[0]?.destination?.[0]?.destination);

// Image validation - pass image in to array validator and check for url existence.
// Use: validateImage(data.image)
export const validateImage = (image) => (validateArray(image) && (image[0].image?.gatsbyImageData || image[0]?.image?.url) ? true : false);

// Formats phone numbers to (###) ###-####
export const formatPhoneNumber = (phone) => {
	const cleaned = ('' + phone).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		let international = match[1] ? '+1 ' : '';
		return [international, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return null;
};

// Removess format of phone numbers for storage
export const removePhoneNumberFormat = (phone) => {
	if (!phone) {
		return '';
	} else {
		return phone.replace(/\(|\)|-|_| /g, '');
	}
};

// set in cookie
export const setCookie = (key, value, expires, noMax = false) => {
	if (typeof window !== 'undefined') {
		if (expires) {
			cookies.set(key, value, {
				path: '/',
				maxAge: !noMax ? expires : null,
				expires: noMax ? expires : null,
			});
		} else {
			cookies.set(key, value, {
				path: '/',
			});
		}
	}
};

// remove from cookie
export const removeCookie = (key) => {
	if (typeof window !== 'undefined') {
		cookies.remove(key, {
			path: '/',
		});
	}
};

// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
	if (typeof window !== 'undefined') {
		return cookies.get(key);
	}
};

// localstorage
export const setLocalStorage = (key, value, stringify = true) => {
	if (storageAvailable('localStorage')) {
		try {
			localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

export const getLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		try {
			// HOTFIX // Can remove at a later date
			// Need to convert postalCode in object to postal_code
			// GuestLocation is sessionStorage and should always update on new session
			if ('userlocation' === key.toLowerCase() && localStorage.getItem(key)) {
				const object = JSON.parse(localStorage.getItem(key));
				if (object.postalCode) {
					object.postal_code = object.postalCode;
					delete object.postalCode;
					localStorage.setItem(key, JSON.stringify(object));
				}
			}
			// END HOTFIX
			return localStorage.getItem(key);
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

export const removeLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

// sessionstorage
export const getSessionStorage = (key) => {
	if (storageAvailable('sessionStorage')) {
		try {
			return sessionStorage.getItem(key);
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

export const setSessionStorage = (key, value, stringify = true) => {
	if (storageAvailable('sessionStorage')) {
		try {
			sessionStorage.setItem(key, stringify ? JSON.stringify(value) : value);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

export const removeSessionStorage = (key) => {
	if (storageAvailable('sessionStorage')) {
		try {
			return sessionStorage.removeItem(key);
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

export const storageAvailable = (type) => {
	// accepts sessionstorage or localstorage
	// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage

	if (typeof window !== 'undefined') {
		var storage;
		try {
			storage = window[type];
			var x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// everything except Firefox
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// test name field too, because code might not be present
					// everything except Firefox
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				storage &&
				storage.length !== 0
			);
		}
	}
};