import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AnimateHeight from 'react-animate-height';
import { MdAdd, MdExpandMore, MdExpandLess } from 'react-icons/md';
import List from "@pella/sash-foundation/dist/list";
import { BaseLink } from '../../../../components';
import Dropdown from '../../../common/Dropdown';
import { Container, Row, Column, Select } from '@pella/sash-foundation';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import './index.scss';

const MobileNav = (props) => {
	const [height, setHeight] = useState(0);
	const [collapse, setCollapse] = useState(false);
	const [activeMenu, setActiveMenu] = useState(null);

	const onNavTabClick = i => setActiveMenu(i);

	const onMobileTrigger = e => {
		e.preventDefault();
		setHeight(height === 0 ? 'auto' : 0);
		setCollapse(!collapse);
	};

	return (
		// <nav className='spal-mobileNav'>
		// 	<List bullets='none'>
		// 		{props.data.map((item, index) => (
		// 			<li key={index}>
		// 				{item.heading &&
		// 					<Dropdown data={item} className='pal-navItem' setActive={onNavTabClick}>
		// 						<Container width={1530}>
		// 							{item.has_subnav &&
		// 								<div className='pal-supportMenu'>
		// 									<List bullets='none'>
		// 										{item.sub_navigation.reference?.[0] && item.sub_navigation.reference.map((link, index) => (
		// 											<li key={index}>
		// 												<BaseLink data={link} />
		// 											</li>
		// 										))}
		// 									</List>
		// 								</div>
		// 							}
		// 						</Container>
		// 					</Dropdown>
		// 				}
		// 				{item.heading_cta?.[0] &&
		// 					<BaseLink data={item.heading_cta[0]} />
		// 				}
		// 			</li>
		// 		))}


		// 		<li>
		// 			<button
		// 				onClick={e => props.logoutTrigger(e)}
		// 				className='spal-mobileNav-LogoutBtn'
		// 			>
		// 				Logout
		// 			</button>
		// 		</li>
		// 	</List>
		// </nav>

		<div id='js-mobileMenu' className={'pal-mobileNav pal-mobileNav--active'}>
			<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
				<List bullets='none' className='pal-mobileNav-List'>
					{props.data.map((item, index) => (
						<React.Fragment key={index}>
							{item.heading_cta?.[0] &&
								<div className='' >
									<BaseLink className='pal-mobileNav-Btn' data={item.heading_cta[0]} />
								</div>
							}
							{item.heading && item.sub_navigation?.reference &&
								<AccordionItem>
									<AccordionItemHeading className='pal-mobileNav-Btn'>
										<AccordionItemButton><MdExpandMore className='coop-menu-expandIconMore' /><MdExpandLess className='coop-menu-expandIconLess' />{item.heading}</AccordionItemButton>
									</AccordionItemHeading>
										<AccordionItemPanel className='itemPanel'>
												<List bullets='none' className='bonelli-mobileSubMenu'>
													{item.sub_navigation.reference.map((sub, index) => (
														<li key={index}>
															<BaseLink data={sub} className='pal-mobileNav-Btn2'>{sub.link[0].default_tite}</BaseLink>
														</li>
													))}
												</List>
										</AccordionItemPanel>
								</AccordionItem>
							}
						</React.Fragment>
				))}
				</List>
			</Accordion>

			<Container>
				<Row justify='center' className='mtop50'>
					<Column>
							<a
								className='pal-mobileNav-Btn2'
								onClick={e => props.logoutTrigger(e)}
							>
								Logout
							</a>
					</Column>
				{props.gData.global_cta.map((item, index) => (
					<Column>
						{/* <div dangerouslySetInnerHTML={{ __html: item.heading }} /> */}
						<BaseLink data={item} className='pal-mobileNav-Btn2' />
					</Column>
				))}
				</Row>
				<Row className='coop-centerContent'>
					{props.role !== "DLR" ? (
						<div className='coop-mobileSelect centerText'>
							<Select
								id='ddlDivision'
								className=''
								labelText='Select Company'
								hideLabel={true}
								onChange={e => props.changeTrigger(e)}
								value={props.selectedCompany}
							>
								{/* <option key={""} value={""}>Default Company</option> */}
								{
									props.companies &&
									props.companies.length > 0 &&
									props.companies.map((option, index) => (
										<option key={index} value={option.id}>{option.name}</option>
									))
								}
							</Select>
						</div>
						) : null
					}
				</Row>
			</Container>
		</div>
	);
};


MobileNav.propTypes = {
	data: PropTypes.object,
	gData: PropTypes.object,
	logoutTrigger: PropTypes.func,
	changeTrigger: PropTypes.func,
};

export default MobileNav;
