import React, { Component } from "react";
import { Container, Row, Column, Button } from '@pella/sash-foundation';

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import Nav from "../../components/common/nav";

import "./index.scss";

class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			corpUsers: {}
		};
	}

	render() {
		return (
			<Layout>
				<SEO title={"Admin"} />

				{/* <Nav /> */}
				<Container className='sf-pb--2xl select-admin'>
					<h1 style={{ fontSize: '2rem' }} className='sf-text--uppercase'>Admin</h1>
					<hr />

					<fieldset className='sf-formGroup'>
						<legend className='sf-display--block'>Select Admin Option</legend>
						<Row>
							<Column xs='12'>
								<Button
									color='black'
									size='md'
									gtmLinkType='button'
									gtmLinkText='User Admin'
									href='/admin/useradmin'
								>
									User Admin
								</Button>
							</Column>
						</Row>
					</fieldset>
				</Container>
			</Layout>
		);
	}
}

export default Admin;
