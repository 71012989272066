import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Container, Row, Column, Button, TextInput, TextArea, List } from '@pella/sash-foundation';
import { isLoggedIn } from '../../../components/common/private-route/private-route';

import Layout from "../../../components/layout/layout-contact";
import SEO from "../../../components/seo";
import Nav from "../../../components/common/nav";

import "./index.scss";

class ContactUs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			txtDealerName: "",
			txtName: "",
			txtPhone: "",
			txtEmail: "",
			txtHelp: "",
			errors: [],
			showSuccess: false,
			successMessage: "",
			hideLinks: true,
			loggedIn: false,
			loaded: false
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onSubmitClick = this.onSubmitClick.bind(this);
	}

	componentDidMount() {

		if (isLoggedIn()){
			this.setState({
				hideLinks: false,
				loggedIn: true,
				loaded: true
			});
		}

	}

	onInputChange(e) {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
			errors: []
		});
	}

	onResetClick(e) {
		e.preventDefault();
		document.getElementById("contact-form").reset();
		this.setState({
			txtDealerName: "",
			txtName: "",
			txtPhone: "",
			txtEmail: "",
			txtHelp: "",
			errors: [],
			showSuccess: false,
			successMessage: ""
		});
	}

	validateFields() {
		let errors = [];

		if (!this.state.txtName) {
			errors.txtName = "Please enter your name.";
		}

		if (!this.state.txtPhone) {
			errors.txtPhone = "Please enter a phone number.";
		}

		if (!this.state.txtEmail) {
			errors.txtEmail = "Please enter a valid email address.";
		}

		if (!this.state.txtHelp) {
			errors.txtHelp = "Please describe how we can help.";
		}

		return errors;
	}

	submitContact() {
		const contactUser = {
			dealerName: this.state.txtDealerName,
			yourName: this.state.txtName,
			phone: this.state.txtPhone,
			email: this.state.txtEmail,
			howCanWeHelpYou: this.state.txtHelp
		};

		axios({
			method: "post",
			url: `${process.env.GATSBY_PELLA_API}/pella-coop/v1/email/contact`,
			//url: `http://localhost:4000/pella-coop/v1/email/contact`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + process.env.GATSBY_PELLA_API_TOKEN
			},
			data: contactUser
		}).then(response => {
			if (response.data.success === true) {
				this.setState({
					showSuccess: true,
					successMessage: response.data.response,
				});
			} else {
				if (response.data.success === false) {
					this.setState({
						showSuccess: true,
						successMessage: "There was a problem sending the email. Please check the form and try again. If the problem persists, please contact portal support."
					});
				}
			}
		});
	}

	onSubmitClick(e) {
		e.preventDefault();

		const { txtName, txtPhone, txtEmail, txtHelp } = this.state;
		const errors = this.validateFields(
			txtName,
			txtPhone,
			txtEmail,
			txtHelp
		);

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors: errors
			});
		} else {
			this.setState({
				success: true,
				errors: []
			});
			this.submitContact();
		}
	}

	render() {
		const { errors, showSuccess, successMessage,loggedIn, hideLinks, loaded } = this.state;

		return (
			<Layout hideLinks={hideLinks}  className='contact-wrapper'>
				<div className='page-topper' />
				<SEO title='Contact Us' />

				{/* <Nav /> */}

				<Container width={1200} className={isLoggedIn() ? 'sf-py--2xl contact-main-section-auth' : 'sf-py--2xl contact-main-section'} >
					<h1 style={{ fontSize: '2rem', color: '#676767' }} className='sf-text--uppercase sf-text--semibold'>
						Contact Us
					</h1>

					<div className='radius-box'>
						<p className='radius-box-title'>Contact Details</p>
						{showSuccess &&
							<Row className='error'>
								<Column xs='12'>
									<p>{successMessage}</p>
								</Column>
							</Row>
						}

						<Row noGutters={true}>
							<Column xs={12} md={6}>
								<p className='title-bar'>Feedback Form</p>

								<form id='contact-form'>
									<List bullets='none'>
										<li className='inline-field'>
											<TextInput
												id='txtDealerName'
												name='txtDealerName'
												value={this.state.txtDealerName || ''}
												labelText='Dealer Name'
												onChange={this.onInputChange}
											/>

											{errors.txtDealerName && (
												<p className='error-text'>
													{errors.txtDealerName}
												</p>
											)}
										</li>

										<li className='inline-field'>
											<TextInput
												id='txtName'
												name='txtName'
												value={this.state.txtName || ''}
												labelText='Your Name'
												required={true}
												onChange={this.onInputChange}
											/>
											{errors.txtName && (
												<p className='error-text'>
													{errors.txtName}
												</p>
											)}
										</li>

										<li className='inline-field'>
											<TextInput
												type='tel'
												id='txtPhone'
												name='txtPhone'
												value={this.state.txtPhone || ''}
												labelText='Phone'
												required={true}
												onChange={this.onInputChange}
											/>
											{errors.txtPhone && (
												<p className='error-text'>
													{errors.txtPhone}
												</p>
											)}
										</li>

										<li className='inline-field'>
											<TextInput
												type='email'
												id='txtEmail'
												name='txtEmail'
												value={this.state.txtEmail || ''}
												labelText='Email'
												required={true}
												onChange={this.onInputChange}
											/>
											{errors.txtEmail && (
												<p className='error-text'>
													{errors.txtEmail}
												</p>
											)}
										</li>

										<li>
											<TextArea
												id='txtHelp'
												name='txtHelp'
												value={this.state.txtHelp || ''}
												labelText='How can we help you?'
												required={true}
												onChange={this.onInputChange}
											/>
											{errors.txtHelp && (
												<p className='error-text'>
													{errors.txtHelp}
												</p>
											)}
										</li>

										<li className='sf-text--right'>
											<Button
												color='black'
												size='sm'
												onClick={this.onResetClick}
											>
												Reset
											</Button>

											<Button
												color='black'
												size='sm'
												className='sf-ml--md'
												onClick={this.onSubmitClick}
											>
												Send
											</Button>
										</li>
									</List>
								</form>
							</Column>

							<Column xs={12} md={6} className='contact-info sf-mt--md sf-mt-790--0'>
								<p className='title-bar'>Contact Information</p>

								<Row className='sf-text--center'>
									<Column
										xs={12}
										md={6}
										className='sf-py--sm sf-py-790--xl'
									>
										<p className='sf-text--bold sf-mb--0'>
											Questions?
										</p>
										<p>
											Hours: 8:00 - 5:00 PM CST
											<br />
											Phone: 866-319-0397
										</p>
									</Column>

									<Column
										xs={12}
										md={6}
										className='sf-py--sm sf-py-790--xl'
									>
										<p className='sf-text--bold sf-mb--0'>
											Email
										</p>
										<p>
											Support:{" "}
											<a href='mailto:portalsupport@pella.com?SUBJECT=Customer Support Request'>
												portalsupport@pella.com
											</a>
										</p>
									</Column>
								</Row>
							</Column>
						</Row>
					</div>
				</Container>
			</Layout>
		);
	}
}

ContactUs.propTypes = {
	data: PropTypes.object
};

export default ContactUs;
