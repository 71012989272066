import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Row, Column, List } from '@pella/sash-foundation';
import { SEO, BaseLink, BaseImage, BaseButton } from '../../components';
import Layout from '../../components/layout/layout';

import './index.scss';
import FaqGroup from '../../components/template/support/faq-group';

const Support = (props) => {
	const pageData = props.data.contentstackSupport;
	const [activeLetter, setActiveLetter] = useState(0);
	const [activeSpec, setActiveSpec] = useState(0);

	const onLetterClick = i => setActiveLetter(i);
	const onSpecTabClick = i => setActiveSpec(i);

	return (
		<Layout className='coop-warranties'>
			{/* <SEO {...pageData.seo} /> */}

			{pageData.heading &&
				<div className='coop-support-Heading'>
					<Container width={1530}>
						<h1 className='sf-m--0'>{pageData.heading}</h1>
					</Container>
				</div>
			}

			{pageData.top_content &&
				<Container width={1530}>
					<div
						className='coop-support-Description sf-my--lg sf-my-1030--xxl'
						dangerouslySetInnerHTML={{ __html: pageData.top_content }}
					/>
				</Container>
			}

			{pageData.modular_blocks?.[0] && pageData.modular_blocks.map((block, index) => {
				if (block.glossary != null) {
					return (
						<div className='sf-my--lg sf-my-1030--xxl' key={index}>
							<Container width={1530}>
								{block.glossary.group?.[0] &&
									<>
										<List bullets='none' layout='inline' className='coop-support-GlossaryList'>
											<li>
												<button
													onClick={() => onLetterClick(-1)}
													className={activeLetter == -1 ? 'coop-support-GlossaryItem--active' : null}
												>
													Show All
												</button>
											</li>

											{block.glossary.group.map((item, index) => (
												<li key={index}>
													<button
														onClick={() => onLetterClick(index)}
														className={activeLetter == index ? 'coop-support-GlossaryItem--active' : null}
													>
														{item.letter}
													</button>
												</li>
											))}
										</List>

										{block.glossary.group.map((panel, index) => (
											<div className={`coop-support-Glossary ${(activeLetter != index && activeLetter != -1) ? 'coop-displayNone' : ''}`} key={index}>
												{panel.letter && <h2>{panel.letter}</h2>}
												{panel.terms &&
													<div
														dangerouslySetInnerHTML={{ __html: panel.terms}}
														className='coop-support-GlossaryTerms'
													/>
												}
											</div>
										))}
									</>
								}
							</Container>
						</div>
					);
				}

				if (block.warranties?.warranty?.[0]) {
					return (
						<div key={index} className='coop-warranties sf-my--lg sf-my-1030--xxl'>
							<Container width={1530}>
								<Row verticalGutters={true}>
									{block.warranties.warranty.map((item, index) => (
										<Column key={index} xs={block.warranties.column_mobile} lg={block.warranties.column_desktop}>
											<div className={`coop-supportIWC-Card coop-supportIWC-Card--warranty coop-supportIWC-Card--left`}>
												{item.image?.[0] &&
													<BaseImage data={item.image[0]} className='coop-supportIWC-CardImage' />
												}
												<div className='coop-supportIWC-CardContent'>
													{item.heading &&
														<h3 className='coop_warrantySub'>{item.heading}</h3>
													}
													{item.subtitle &&
														<p className='coop_warrantySub' dangerouslySetInnerHTML={{ __html: item.subtitle }} />
													}
													{item.description &&
														<div dangerouslySetInnerHTML={{ __html: item.description }} />
													}
													{item.cta?.[0] &&
														<List bullets='none' className='coop-supportIWC-CardBtns'>
															{item.cta.map((link, index) => (
																<li key={index}>
																	<BaseButton color='none' data={link} />
																</li>
															))}
														</List>
													}
												</div>
											</div>
										</Column>
									))}
								</Row>
								<Row>
									{block.warranties.cta.map((link, index) => (
										<List bullets='none' className='coop-supportIWC-CardBtns'>
											<li key={index}>
												<BaseButton data={link} />
											</li>
										</List>
									))}
								</Row>
							</Container>
						</div>
					)
				}

				if (block.manuals?.manuals?.[0]) {
					return (
						<div key={index} className='coop-warranties sf-my--lg sf-my-1030--xxl'>
							<Container width={1530}>
								<Row verticalGutters={true}>
									{block.manuals.manuals.map((item, index) => (
										<Column key={index} xs={12} lg={6}>
											<div className={`coop-supportIWC-Card coop-supportIWC-Card--warranty coop-supportIWC-Card--left`}>
												{item.image?.[0] &&
													<BaseImage data={item.image[0]} className='coop-supportIWC-CardImage' />
												}
												<div className='coop-supportIWC-CardContent'>
													{item.heading &&
														<h3 className='coop_warrantySub'>{item.heading}</h3>
													}
													{item.subtitle &&
														<p className='coop_warrantySub' dangerouslySetInnerHTML={{ __html: item.subtitle }} />
													}
													{item.description &&
														<div dangerouslySetInnerHTML={{ __html: item.description }} />
													}
													{item.cta?.[0] &&
														<List bullets='none' className='coop-supportIWC-CardBtns'>
															{item.cta.map((link, index) => (
																<li key={index}>
																	<BaseButton data={link} />
																</li>
															))}
														</List>
													}
												</div>
											</div>
										</Column>
									))}
								</Row>
							</Container>
						</div>
					)
				}

				if (block.faqs?.group?.[0]) {
					return <FaqGroup data={block.faqs} key={index} />;
				}

				if (block.specs?.group?.[0]) {
					return (
						<div className='sf-my--lg sf-my-1030--xxl' key={index}>
							<Container width={1530}>
								<List bullets='none' layout='inline' className='coop-support-SpecsTabs'>
									{block.specs.group.map((tab, index) => (
										<li key={index}>
											<button
												onClick={() => onSpecTabClick(index)}
												className={activeSpec == index ? 'coop-support-SpecsTab--active' : null}
											>
												{tab.tab_name}
											</button>
										</li>
									))}
								</List>

								{block.specs.group.map((panel, index) => (
									<div className={activeSpec != index ? 'coop-displayNone' : null} key={index}>
										<Row key={index} className='coop-support-SpecsHead' noGutters={true}>
											<Column xs={12} sm={6}>
												<div className='coop-support-SpecsCell'>&nbsp;</div>
											</Column>

											<Column xs={12} sm={6}>
												<div className='coop-support-SpecsCell'>
													Full Product Information
												</div>
											</Column>
										</Row>

										{panel.group?.[0] && panel.group.map((cell, index) => (
											<Row key={index} className='coop-support-SpecsRow' noGutters={true}>
												<Column xs={12} sm={6}>
													<div className='coop-support-SpecsCell'>
														{cell.product}
													</div>
												</Column>

												{cell.cta?.[0] &&
													<Column xs={12} sm={6}>
														<div className='coop-support-SpecsCell'>
															<BaseLink data={cell.cta[0]} />
														</div>
													</Column>
												}
											</Row>
										))}
									</div>
								))}
							</Container>
						</div>
					);
				}
			})}
		</Layout>
	);
};

Support.propTypes = {
	data: PropTypes.object
};

export default Support;

export const query = graphql`
	query($uid: String!) {
		contentstackSupport(uid: { eq: $uid }) {
			url
			heading
			top_content
			modular_blocks {
				faqs {
					heading
					group {
						tab_name
						faqs {
							...FAQ
						}
					}
				}
				specs {
					heading
					group {
						tab_name
						group {
							product
							cta {
								...CTA
							}
						}
					}
				}
				glossary {
					heading
					group {
						letter
						terms
					}
				}
				warranties {
					column_desktop
					column_mobile
					warranty {
						...ImageWithContent
					}
					cta {
						...CTA
					}
				}
				manuals {
					heading
					manuals {
						...ImageWithContent
					}
				}
			}
			#seo {
			#	title: heading
			#	keywords
			#	description
			#}
		}
	}
`;
