import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Header, Footer } from '../../components';
import { isLoggedIn } from '../common/private-route/private-route';

import './layout.scss';

const Layout = ({pageURL, TopCTA, hideLinks, className, children, ...other }) => {
	const skipTo = useRef(null);
	const [userLoggedIn, setUserLoggedIn] = useState(false);

	useEffect(() => {
		if (isLoggedIn()) {
			setUserLoggedIn(true);
		}

		if (typeof window !== "undefined" && !isLoggedIn()) {
			navigate('/login/', { state: {
				prevUrl: window.location.pathname,
				params: window.location.search
			}});
			
			//REMOVE // after testing
		}
	}, []);

	const onSkipContentClick = () => {
		skipTo.current.focus();
	};

	return (
		<div id='pella-site-content' className={className || null}>
			<a href='#content' className='spal-skipTo sf-readerOnly' onClick={() => onSkipContentClick()}>
				Skip to Content
			</a>

			<Header pageURL={pageURL} hideLinks={hideLinks} TopCTA={TopCTA} />
			<main id='content' ref={skipTo} {...other}>
				{children}
			</main>
			<Footer hideLinks={hideLinks}/>
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	pageURL: PropTypes.string,
	TopCTA: PropTypes.bool,
	hideLinks: PropTypes.bool
};

export default Layout;