export const validateStoreForm = (data) => {
	let errors = [];

	if (!data.storeNameNumber) {
		errors.storeNameNumber = 'Please enter a store name and number.';
	}

	if (!data.storeContactName) {
		errors.storeContactName = 'Please enter a contact name.';
	}

	if (!data.bestContactNumber) {
		errors.bestContactNumber = 'Please enter a contact phone number.';
	}

	if (!data.storeContactEmail) {
		errors.storeContactEmail = 'Please enter a contact email address.';
	}

	if (!data.jobName) {
		errors.jobName = 'Please enter a contact job name.';
	}
	if (!data.originalPONumber) {
		errors.originalPONumber = 'Please enter the original PO Number.';
	}
	return errors;
};