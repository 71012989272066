import React from "react";
import PropTypes from "prop-types";

const Link = (props) => {
	const { data, children, ...other } = props;

	if (!data || !data.link)
		return null

	const {
		link={},
		gtm_link_type,
		gtm_link_text,
		target
	} = data;

	return (
		<a
			href={link.href}
			title={link.title}
			data-gtm-linktype={gtm_link_type}
			data-gtm-linktext={gtm_link_text}
			target={target}
			{...other}
		>
			{children || link.title}
		</a>
	);
};

Link.propTypes = {
	data: PropTypes.object,
	children: PropTypes.node
};

export default Link;
