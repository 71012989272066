import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({ description, lang, keywords, title, pageTitle}) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription =	description || data.site.siteMetadata.description;

				return (
					<Helmet
						htmlAttributes={{
							lang,
						}}
						title={pageTitle || title}
						titleTemplate={`%s | ${data.site.siteMetadata.title}`}
						meta={[
							{
								name: "description",
								content: metaDescription,
							},
							{
								property: "og:title",
								content: title,
							},
							{
								property: "og:description",
								content: metaDescription,
							},
							{
								property: "og:type",
								content: "website",
							},
							{
								name: "title",
								content: title,
							},
							{
								name: "keywords",
								content: keywords || ""
							}
						]}
					/>
				);
			}}
		/>
	);
}

SEO.defaultProps = {
	lang: "en",
	meta: [],
	keywords: "",
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.string,
	title: PropTypes.string.isRequired,
	pageTitle: PropTypes.string
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
