import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { getSessionStorage } from '../../utlities/functions';

const cookies = new Cookies();

const EloquaForm = (props) => {
	const { elqFormName, elqSiteID = '1920192983', elqCookieWrite = 0, children, className, onFormSubmit, utm, ...other } = props;
	const [utmParams, setUtmParams] = useState({
		campaign: utm?.utm_campaign || '',
		source: utm?.utm_source || '',
		medium: utm?.utm_medium || '',
		content: utm?.utm_content || '',
		term: utm?.utm_term || '',
	});

	useEffect(() => {
		() => EloquaGUID();
		var _elqQ = _elqQ || [];
		_elqQ.push(['elqGetCustomerGUID']);

		if (!utm || (utm && Object.keys(utm).length === 0)) {
			// only run if query strings aren't found
			checkStorageForUTM();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- preexisting failure, fix when possible
	}, []);

	let timerId = null,
		timeout = 5;
	const EloquaGUID = () => {
		if (!!timerId) {
			if (timeout === 0) return;

			if (typeof this.GetElqCustomerGUID === 'function') {
				document.forms[`${elqFormName}`].elements['elqCustomerGUID'].value = GetElqCustomerGUID();
				return;
			}
			timeout -= 1;
		}

		timerId = setTimeout('() => EloquaGUID()', 500);
		return;
	};

	// Grab ELOQUA cookie value, split it and pop it to remove GUID=
	const guid = cookies.get('ELOQUA');
	const guidValue = guid != null ? guid.split('=').pop() : '';

	const checkStorageForUTM = () => {
		const urlstring = getSessionStorage('urlstring');
		if (urlstring) {
			let params = {};
			const utms = JSON.parse(urlstring).split('^^');

			if (utms.find((utm) => utm.includes('utmsource'))) {
				params.source = utms.find((utm) => utm.includes('utmsource')).split(':')[1];
			}
			if (utms.find((utm) => utm.includes('utmcampaign'))) {
				params.campaign = utms.find((utm) => utm.includes('utmcampaign')).split(':')[1];
			}
			if (utms.find((utm) => utm.includes('utmmedium'))) {
				params.medium = utms.find((utm) => utm.includes('utmmedium')).split(':')[1];
			}
			if (utms.find((utm) => utm.includes('utmcontent'))) {
				params.content = utms.find((utm) => utm.includes('utmcontent')).split(':')[1];
			}
			if (utms.find((utm) => utm.includes('utmterm'))) {
				params.term = utms.find((utm) => utm.includes('utmterm')).split(':')[1];
			}

			setUtmParams(params);
		}
	};

	return (
		<form
			id={elqFormName}
			name={elqFormName}
			className={className || ''}
			onSubmit={onFormSubmit}
			noValidate='off'
			{...other}
		>
			<input
				type='hidden'
				name='elqFormName'
				value={elqFormName}
			/>
			<input
				type='hidden'
				name='elqSiteID'
				value={elqSiteID}
			/>
			<input
				type='hidden'
				name='elqCustomerGUID'
				value={guidValue}
			/>
			<input
				type='hidden'
				name='elqCookieWrite'
				value={elqCookieWrite}
			/>
			<input
				type='hidden'
				name='env'
				value={process.env.GATSBY_CONTENTSTACK_ENVIRONMENT}
			/>
			<input
				type='hidden'
				name='utm-campaign'
				value={utmParams.campaign || ''}
			/>
			<input
				type='hidden'
				name='utm-source'
				value={utmParams.source || ''}
			/>
			<input
				type='hidden'
				name='utm-medium'
				value={utmParams.medium || ''}
			/>
			<input
				type='hidden'
				name='utm-content'
				value={utmParams.content || ''}
			/>
			<input
				type='hidden'
				name='utm-term'
				value={utmParams.term || ''}
			/>

			{children}
		</form>
	);
};

EloquaForm.propTypes = {
	elqFormName: PropTypes.string.isRequired,
	elqSiteID: PropTypes.string,
	elqCookieWrite: PropTypes.number,
	onFormSubmit: PropTypes.func,
	className: PropTypes.string,
	utm: PropTypes.object,
	children: PropTypes.node,
};

export default EloquaForm;
